import React from 'react';
import { NavBodyStyled } from './styled';
import { Outlet } from 'react-router-dom';
import { Navbar } from 'widgets';

const NavBody = () => {
  return (
    <NavBodyStyled>
      <Navbar />
      <div className="body_main_layout">
        <Outlet />
      </div>
    </NavBodyStyled>
  );
};

NavBody.propTypes = {};

export default NavBody;
