import styled from 'styled-components';

export const InputCheckBoxStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  .theme_standard_input_check_box_round {
    display: flex;
    flex-wrap: wrap;
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 18px;
      max-width: 18px;
      width: 100%;
      height: 100%;
      // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid #9e9e9e;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      border: 1px solid ${({ theme }) => theme.COLORS.PINK_1};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 2.9px;
      left: 2.9px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: ${({ theme }) => theme.COLORS.PINK_1};
    }
  }

  .theme_standard_input_check_box_square {
    display: flex;
    flex-wrap: wrap;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_3};
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    .lable_show {
      margin-left: 10px;
      color: ${({ theme }) => theme.COLORS.BLACK_4};
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 22px;
      max-width: 22px;
      width: 100%;
      height: 100%;
      // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid #707070;
      border-radius: 6px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      // border: 1px solid #F0211E;
      background: #3b424c;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      left: 7px;
      top: 4px;
      width: 3px;
      height: 8px;
      border: solid #ffff;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .theme_standard_input_check_box_input_round {
    display: flex;
    flex-wrap: wrap;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLUE_4};
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    .lable_show {
      margin-left: 10px;
      color: ${({ theme }) => theme.COLORS.BLACK_4};
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 16px;
      max-width: 16px;
      width: 100%;
      height: 100%;
      // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid #707070;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      // border: 1px solid #F0211E;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: ${({ theme }) => theme.COLORS.BLUE_6};
    }
  }

  .theme_standard_input_check_box_input_round_2 {
    display: flex;
    flex-wrap: wrap;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLUE_4};
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    .lable_show {
      margin-left: 10px;
      color: ${({ theme }) => theme.COLORS.BLACK_4};
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 16px;
      max-width: 16px;
      width: 100%;
      height: 100%;
      // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid #ffff;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      // border: 1px solid #F0211E;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: ${({ theme }) => theme.COLORS.BLUE_6};
    }
  }

  .theme_standard_input_check_box_input_round_3 {
    display: flex;
    flex-wrap: wrap;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLUE_4};
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: flex;
      align-items: center;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    .img_logo {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      flex-shrink: 0;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid #f5f5f5;
      margin-left: 10px;
    }
    .lable_show {
      margin-left: 10px;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      margin-top: 15px;
      max-height: 16px;
      max-width: 16px;
      width: 100%;
      height: 100%;
      // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid ${({ theme }) => theme.COLORS.BLUE_6};
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      // border: 1px solid #F0211E;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: ${({ theme }) => theme.COLORS.BLUE_6};
    }
  }

  .theme_standard_input_check_box_square_account {
    display: flex;
    flex-wrap: wrap;
    // font-size: ${({ theme, fontSize }) =>
      fontSize ? fontSize : theme.FONT.SIZE.S14};
    // font-family: ${({ fontFamily }) =>
      fontFamily ? fontFamily : 'noto_sans_thai_regular, noto_sans_regular'};
    // color:  ${({ theme, fontColor }) =>
      fontColor ? fontColor : theme.COLORS.PURPLE_1};

    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    .lable_show {
      margin-left: 10px;
      color: ${({ theme }) => theme.COLORS.BLACK_4};
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 18px;
      max-width: 18px;
      width: 100%;
      height: 100%;
      // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      border-radius: 2px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      // border: 1px solid #F0211E;
      background: gold;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      left: 6px;
      top: 2px;
      width: 3px;
      height: 8px;
      border: solid black;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;
