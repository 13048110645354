import React from 'react';
import { InputCheckBoxStyled } from './styled';
import cx from 'classnames';

const InputCheckBox = ({
  theme_standard_input_check_box,
  theme_standard_input_check_box_round,
  theme_standard_input_check_box_square,
  theme_standard_input_check_box_square_account,
  theme_standard_input_check_box_input_round,
  theme_standard_input_check_box_input_round_2,
  theme_standard_input_check_box_input_round_3,
  label,
  value,
  checked,
  img,
  fontSize,
  fontFamily,
  fontColor,
  onChange,
  disabled,
}) => {
  const customClass = cx({
    theme_standard_input_check_box: theme_standard_input_check_box,
    theme_standard_input_check_box_round: theme_standard_input_check_box_round,
    theme_standard_input_check_box_square:
      theme_standard_input_check_box_square,
    theme_standard_input_check_box_square_account:
      theme_standard_input_check_box_square_account,
    theme_standard_input_check_box_input_round:
      theme_standard_input_check_box_input_round,
    theme_standard_input_check_box_input_round_2:
      theme_standard_input_check_box_input_round_2,
    theme_standard_input_check_box_input_round_3:
      theme_standard_input_check_box_input_round_3,
  });

  return (
    <InputCheckBoxStyled
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontColor={fontColor}
    >
      <div className={customClass}>
        <label className="container">
          <input
            type="checkbox"
            name={value}
            value={value}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
          />
          <span className="checkmark"></span>
          {img && <img className="img_logo" src={img} alt="logo" />}

          {label && <span className="lable_show">{label}</span>}
        </label>
      </div>
    </InputCheckBoxStyled>
  );
};

export default InputCheckBox;
