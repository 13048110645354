import styled from 'styled-components';

export const BgStandardStyled = styled.div`
  .btn {
    border: 1px solid
      ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.PINK_1};
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    background: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.GRAY_11 : theme.COLORS.PINK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    color: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.GRAY_11 : theme.COLORS.WHITE_1};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  .theme_cancel {
    .btn {
      border: 1px solid ${({ theme }) => theme.COLORS.PINK_1};
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      color: ${({ theme }) => theme.COLORS.PINK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    }
  }

  .theme_login {
    .btn {
      padding: 12px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    }
  }
`;
