import styled from 'styled-components';

export const BtnNormalStyled = styled.div`
  .btn {
    border-radius: 6px;
    width: 100%;
    padding: 8px 20px;
    background: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.GRAY_11 : theme.COLORS.BLUE_1};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${(props) =>
      props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme, fontFamilyTop }) => fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.KANIT_MEDIUM};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  .theme_big {
    .btn {
      border-radius: 8px;
      padding: 10px 12px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
    }
  }
  .theme_log_in {
    .btn {
      border-radius: 8px;
      padding: 10px 12px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.RED_2};
    }
  }
  .theme_round_purple {
    .btn {
      border-radius: 100px;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.PURPLE_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      padding: 5px 10px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
  }
  .theme_round_white {
    .btn {
      border-radius: 100px;
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      padding: 5px 10px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
  }
  .theme_blue {
    .btn {
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.BLUE_7};
    }
  }
  .theme_white {
    .btn {
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.WHITE_1};
      color: ${({ theme }) => theme.COLORS.BLUE_6};
      border: 1px solid ${({ theme }) => theme.COLORS.BLUE_6};;
    }
  }
`;
