import React from 'react';
import { connect } from 'react-redux';
import { VideoEditContainerStyled } from './styled';
import { Forms, Displays } from 'components';
import { userService } from 'apiService';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { withRouter } from 'navigations/withRouter';

class VideoEditContainer extends React.Component {
  state = {
    isLoading: true,
    isDisabled: false,
    labelNotic:
      this.props.router.params.id !== 'create'
        ? {
          label1: 'บันทึกสำเร็จ',
          label2: 'บันทึกไม่สำเร็จ',
        }
        : {
          label1: 'สร้างสำเร็จ',
          label2: 'สร้างไม่สำเร็จ',
        },
  };

  componentDidMount() {
    this.scrollToTop();
    if (this.props.router.params.id !== 'create') {
      this.fetchData();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await userService.GET_CONTENT_DETAIL(this.props.router.params.id);
    if (res && res.success) {
      let tempCat = []
      let tempTag = []

      res.data.categories.forEach((e) => {
        tempCat.push({ ...e, label: e.name_th, value: e.uuid })
      })
      res.data.tags.forEach((e) => {
        tempTag.push({ ...e, id: e.tag, text: e.tag })
      })

      this.setState({
        initialValues: { ...res.data, categories: tempCat, tags: tempTag },
        isLoading: false,
      });
    }
  };

  checkIfValidUUID = (str) => {
    // Regular expression to check if string is a valid UUID
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(str);
  };

  onSubmit = async (values) => {
    const { labelNotic } = this.state;
    let params = {
      ...values,
      topic: values.topic,
      body: values.body,
      is_draft: values.is_draft?.value === 'true' ? true : false,
      categories: values.categories.map((e) => ({ uuid: e.value })),
      tags: values.tags.map((e) =>
        this.checkIfValidUUID(e.id) ? { uuid: e.id } : { tag: e.id }
      ),
    };
    this.setState({
      isDisabled: true,
    });
    let res =
      this.props.router.params.id === 'create'
        ? await userService.POST_CONTENT_DETAIL(params)
        : await userService.PATCH_CONTENT_DETAIL(
          this.props.router.params.id,
          params
        );
    if (res && res.success) {
      const coverTemp = [
        ...values.cover.filter((e) => e.isNewUpload || e.isDelete),
      ];
      const video480Temp = [
        ...values.video480.filter((e) => e.isNewUpload || e.isDelete),
      ];
      const video720Temp = [
        ...params.video720.filter((e) => e.isNewUpload || e.isDelete),
      ];
      const video1080Temp = [
        ...params.video1080.filter((e) => e.isNewUpload || e.isDelete),
      ];
      let tempParams = [];
      if (coverTemp && coverTemp.length > 0) {
        tempParams.push(...coverTemp);
      }
      if (video480Temp && video480Temp.length > 0) {
        tempParams.push(...video480Temp);
      }
      if (video720Temp && video720Temp.length > 0) {
        tempParams.push(...video720Temp);
      }
      if (video1080Temp && video1080Temp.length > 0) {
        tempParams.push(...video1080Temp);
      }
      if (tempParams && tempParams.length > 0) {
        this.handleUploadFeature(res.data.uuid, tempParams);
      } else {
        toast.success(labelNotic.label1);
        this.props.router.navigate(`${ROUTE_PATH.VIDEO}`);
      }
    } else {
      this.setState({
        isDisabled: false,
      });
      toast.error(labelNotic.label2);
    }
  };

  handleUploadFeature = (content_uuid, files) => {
    const { labelNotic } = this.state;

    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set('type', this.renderUploadType(e.fieldName));
            bodyFormData.append('files[]', e);
            let res = await userService.POST_UPLOAD_CONTENT_DETAIL(
              content_uuid,
              e.fieldName,
              bodyFormData
            );
            if (res && res.success) {
              resolve(true);
            }
          }
          if (e.isDelete) {
            let res = await userService.DELETE_UPLOAD_CONTENT_DETAIL(
              content_uuid,
              e.fieldName
            );
            if (res && res.success) {
              resolve(true);
            }
          }
        });
      })
    ).then(() => {
      toast.success(labelNotic.label1);
      this.props.router.navigate(`${ROUTE_PATH.VIDEO}`);
    });
  };

  renderUploadType = (e) => {
    switch (e) {
      case 'files':
        return 'video';
      case 'covers':
        return 'cover';
      default:
        return e;
    }
  };

  renderType = (type) => {
    switch (type) {
      case 'image/jpeg':
      case 'image/png':
        return 'cover';
      case 'audio/mpeg':
        return 'audio';
      case 'video/mp4':
        return 'video';
      default:
        break;
    }
  };

  handleBack = () => {
    if (this.props.router.params.id === 'create') {
      this.props.router.navigate(`${ROUTE_PATH.VIDEO}`);
    } else {
      this.props.router.navigate(`${ROUTE_PATH.VIDEO_DETAIL_PREVIEW}/${this.props.router.params.id}`);
    }
  };

  render() {
    const { initialValues, isLoading, isDisabled } = this.state;
    // const { authenRedux, contentCategory } = this.props;
    return (
      <VideoEditContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Displays.Loading theme_standard_loading />
          </div>
        ) : (
          <div className="content_layout_show">
            <Forms.AddVideoForm
              initialValues={initialValues}
              // authenRedux={authenRedux}
              // categorySelect={contentCategory}
              isDisabled={isDisabled}
              onSubmit={this.onSubmit}
              handleBack={this.handleBack}
            />
          </div>
        )}
      </VideoEditContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VideoEditContainer));
