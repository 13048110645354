import styled from 'styled-components';

export const AddContentFormStyled = styled.div`
  .clinic_show {
    display: flex;
    justify-content: space-between;
    margin-bottom: 39px;
    align-items: flex-end;
    .back_link {

    }
  }
  .top_form_show {
    display: flex;
    justify-content: space-between;
    .left_box {
      display: flex;
      align-items: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S24};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLUE_4};
      .svg_edit {
        display: flex;
        margin-right: 12px;
      }
    }
    .btn_add {
      width: 264px;
    }
  }
  .body_form {
    background:  ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 18px;
    border-radius: 18px;
    border: 1px solid ${({ theme }) => theme.COLORS.PINK_2};
    padding: 23px 48px;
    padding-bottom: 70px;
    margin-top: 32px;
    .input_top {
      .layer_two {
        margin-top: 7px;
        display: flex;
        justify-content: space-between;
        .right_layer_two {
          width: 58%;
          .right_text_rich {
            margin-top: 17px;
          }
          .box_input {
            margin-top: 20px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
            color: ${({ theme }) => theme.COLORS.BLUE_4};
            .label_show {
              margin-bottom: 10px;
            }
          }
          .group_top_form_right {
            width: 60%;
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
            color: ${({ theme }) => theme.COLORS.BLUE_4};
            margin-bottom: 32px;
            .line_label {
              display: flex;
              margin-bottom: 14px;
              .label_1 {
                margin-right: 18px;
              }
              .label_2 {
                font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                color: ${({ theme }) => theme.COLORS.GRAY_6};
                padding-top: 5px;
              }
            }
          }
        }
  
        .left_layer_two {
          width: 35%;
          .top_form {
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
            color: ${({ theme }) => theme.COLORS.BLUE_4};
            margin-bottom: 32px;
            width: 100%;
            .group_top_form {
              width: 100%;
              .line_label {
                display: flex;
                margin-bottom: 14px;
                .label_1 {
                  margin-right: 18px;
                }
                .label_2 {
                  font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                  color: ${({ theme }) => theme.COLORS.GRAY_6};
                  padding-top: 5px;
                }
              }
            }
          }
          .mid_form {
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
            color: ${({ theme }) => theme.COLORS.BLUE_4};
            .line_mid {
              display: flex;
              flex-direction: column;
              .mid_left {
                width: 100%;
                // margin-right: 24px;
                .group_mid_form {
                  margin-bottom: 8px;
                  &.no_margin {
                    margin-bottom: 0px;
                  }
                  
                  .line_label {
                    display: flex;
                    margin-bottom: 14px;
                    &.mt12 {
                      margin-top: 12px;
                    }
                    .label_1 {
                      margin-right: 18px;
                    }
                    .label_2 {
                      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                      color: ${({ theme }) => theme.COLORS.GRAY_6};
                      padding-top: 5px;
                    }
                  }
                }
              }
              .mid_right {
                // width: 59%;
                margin-bottom: 16px;
                .group_mid_form_right {
                  &.no_margin {
                    margin-bottom: 0px;
                  }
                  &.mb10 {
                    margin-bottom: 10px;
                  }
                  .line_label {
                    display: flex;
                    margin-bottom: 14px;
                    .label_1 {
                      margin-right: 18px;
                    }
                    .label_2 {
                      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                      color: ${({ theme }) => theme.COLORS.GRAY_6};
                      padding-top: 5px;
                    }
                  }
                }
              }
            }
            .show_file_name {
              margin-bottom: 42px;
            }
          }
          .bottom_form {
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
            color: ${({ theme }) => theme.COLORS.BLUE_4};
            display: flex;
            margin-bottom: 23px;
            .bottom_left {
              width: 100%;
              // margin-right: 24px;
              .group_bottom_form {
                margin-bottom: 23px;
                &.no_margin {
                  margin-bottom: 0px;
                }
                &.mb10 {
                  margin-bottom: 10px;
                }
                .line_label {
                  display: flex;
                  margin-bottom: 14px;
                  .label_1 {
                    margin-right: 18px;
                  }
                  .label_2 {
                    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                    color: ${({ theme }) => theme.COLORS.GRAY_6};
                    padding-top: 5px;
                  }
                }
              }
              .reward_opt {
                .show_reward_layer {
                  .title_show {
                    display: flex;
                    margin-bottom: 17px;
                    .show_icon {
                      margin-right: 4px;
                      display: flex;
                    }
                  }
                  .box_input_bottom {
                    margin-bottom: 24px;
                    .left_box {
                    }
                    .right_box {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      margin-top: 16px;
                      .body_input {
                        width: 86%;
                      }
                      .label_b {
                        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
                        ffont-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
                      }
                    }
                    .between_label {
                      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
                      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
                      color: ${({ theme }) => theme.COLORS.BLUE_4};
                    }
                    &.no_margin {
                      margin-bottom: 0px;
                    }
                  }
                  &.left {
                    padding-right: 24px;
                  }
                  &.right {
                    padding-left: 24px;
                  }
                }
              }
            }
            .bottom_right {
              width: 59%;
              .group_bottom_form_right {
                margin-bottom: 23px;
                &.no_margin {
                  margin-bottom: 0px;
                }
                &.mb10 {
                  margin-bottom: 10px;
                }
                .line_label {
                  display: flex;
                  margin-bottom: 14px;
                  .label_1 {
                    margin-right: 18px;
                  }
                  .label_2 {
                    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                    color: ${({ theme }) => theme.COLORS.GRAY_6};
                    padding-top: 5px;
                  }
                }
              }
            }
          }
          .opt_show {
            display: flex;
            .left_opt {
              width: 41%;
              margin-right: 24px;
              .group_opt {
                .line_label {
                  line-height: 1.2;
                  font-size: ${({ theme }) => theme.FONT.SIZE.S20};
                  font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
                  color: ${({ theme }) => theme.COLORS.BLUE_4};
                  margin-bottom: 19px;
                  .label_1 {
                    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
                  }
                  .label_2 {
                    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                    color: ${({ theme }) => theme.COLORS.GRAY_6};
                  }
                }
              }
              .show_img {
                margin-top: 32px;
              }
            }
          }
          .zone_upload {
            margin-top: 20px;
            .header_zone {
              line-height: 1.2;
              font-size: ${({ theme }) => theme.FONT.SIZE.S20};
              font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
              color: ${({ theme }) => theme.COLORS.BLUE_4};
              display: flex;
              margin-bottom: 23px;
              align-items: baseline;
              .label_1 {
                font-size: ${({ theme }) => theme.FONT.SIZE.S18};
              }
              .label_2 {
                margin-left: 14px;
                font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                color: ${({ theme }) => theme.COLORS.GRAY_6};
              }
            }
            .upload_layer {
            }
          }
        }
      }
    }
  }
`;
