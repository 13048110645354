import { ROUTE_PATH } from 'utils/constants/routePath';
import VedioContainer from 'containers/VideoSystem/VideoContainer';
import VideoEditContainer from 'containers/VideoSystem/VideoEditContainer';
import VideoDetailContainer from 'containers/VideoSystem/VideoDetailContainer';
import VideoCategoryContainer from 'containers/VideoSystem/VideoCategoryContainer';
import ContentContainer from 'containers/ContentSystem/ContentContainer';
import ContentDetailContainer from 'containers/ContentSystem/ContentDetailContainer';
import ContentEditContainer from 'containers/ContentSystem/ContentEditContainer';

const cmsRoutes = [
  {
    key: 'video',
    name: 'video',
    path: ROUTE_PATH.VIDEO,
    element: <VedioContainer />,
  },
  {
    key: 'video',
    path: ROUTE_PATH.VIDEO_DETAIL_PREVIEW_BY_ID,
    name: 'video',
    element: <VideoDetailContainer />,
  },
  {
    key: 'video',
    path: ROUTE_PATH.VIDEO_DETAIL_ADD_EDIT,
    name: 'video',
    element: <VideoEditContainer />,
  },
  {
    key: 'video',
    path: ROUTE_PATH.VIDEO_CATEGORY,
    name: 'video',
    element: <VideoCategoryContainer />,
  },
  {
    key: 'content',
    name: 'content',
    path: ROUTE_PATH.CONTENT,
    element: <ContentContainer />,
  },
  {
    key: 'content',
    path: ROUTE_PATH.CONTENT_DETAIL_PREVIEW_BY_ID,
    name: 'content',
    element: <ContentDetailContainer />,
  },
  {
    key: 'video',
    path: ROUTE_PATH.CONTENT_DETAIL_ADD_EDIT,
    name: 'video',
    element: <ContentEditContainer />,
  },
];

export default cmsRoutes;
