export default function renderIcon({ color = '#898e94' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.75"
      height="19.5"
      viewBox="0 0 21.75 19.5"
    >
      <g
        id="Group_84785"
        data-name="Group 84785"
        transform="translate(-1860.25 -21.77)"
      >
        <path
          id="Path_102975"
          data-name="Path 102975"
          d="M11.25,22.5H6.75a2.136,2.136,0,0,1-2.25-2V6.5a2.136,2.136,0,0,1,2.25-2h4.5"
          transform="translate(1856.5 18.02)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_102976"
          data-name="Path 102976"
          d="M24,20.5l5.625-5L24,10.5"
          transform="translate(1851.625 16.02)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_102977"
          data-name="Path 102977"
          d="M27,18H13.5"
          transform="translate(1854.25 13.52)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
