import styled from 'styled-components';

export const CategoryFormStyled = styled.div`
  .cf_action_row {
    margin: auto;
    margin-top: 40px;
    width: 100%;
    max-width: 496px;
    display: flex;
    column-gap: 32px;
    .cf_btn_wrap {
      flex: 1;
    }
  }
`;
