import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BtnPrefixIconStyled } from './styled';

const BtnPrefixIcon = ({
  theme_min_blue,
  theme_white_shadow,
  theme_white_border_color,
  theme_gray_border,
  theme_back_gray,
  theme_red,
  theme_white,
  theme_red_border,
  theme_blue,
  shadow,
  theme_pink,
  theme_opacity,
  theme_blue_height,
  type = 'button',
  fontSize,
  fontFamily,
  labelBottom,
  icon,
  iconBack,
  label,
  onClick,
  disabled,
  fontFamilyBottom,
  fontSizeLabelButtom,
  colorBottom,
  marginTopBottomLabel,
}) => {
  const customClass = cx({
    theme_min_blue: theme_min_blue,
    theme_gray_border: theme_gray_border,
    theme_back_gray: theme_back_gray,
    theme_white: theme_white,
    theme_red_border: theme_red_border,
    theme_pink: theme_pink,
    theme_red: theme_red,
    theme_blue: theme_blue,
    theme_opacity: theme_opacity,
    theme_white_shadow: theme_white_shadow,
    theme_white_border_color: theme_white_border_color,
    theme_blue_height: theme_blue_height,
  });
  return (
    <BtnPrefixIconStyled
      disabled={disabled}
      fontSize={fontSize}
      fontFamily={fontFamily}
      shadow={shadow}
      fontFamilyBottom={fontFamilyBottom}
      fontSizeLabelButtom={fontSizeLabelButtom}
      colorBottom={colorBottom}
      marginTopBottomLabel={marginTopBottomLabel}
    >
      <div className={customClass}>
        <button
          className="btn"
          disabled={disabled}
          type={type}
          onClick={onClick}
        >
          {icon && icon}
          {label}
          {iconBack && iconBack}
        </button>
        {labelBottom && <div className="label_bottom">{labelBottom}</div>}
      </div>
    </BtnPrefixIconStyled>
  );
};

BtnPrefixIcon.propTypes = {};

export default BtnPrefixIcon;
