import { PlayAudioStyled } from './styled';
import ReactPlayer from 'react-player';
import React, { useState } from 'react';
import { Icons } from 'components'

const PlayAudio = ({ url }) => {
  const [isPlay, setIsPlay] = useState(false);

  const runFile = () => {
    setIsPlay(!isPlay)
  }
  return (
    <PlayAudioStyled>
      <div className="mp3_wrap" onClick={() => runFile()}>
        <div className='show_svg' >
          <Icons.TypeSound
            width='33'
            height='30.8'
          />
        </div>
        <ReactPlayer url={url} width="0%" height="0px" playing={isPlay} />
        <div>
          {`${isPlay ? `Stop` : `Play`} ตัวอย่างไฟล์เสียง`}
        </div>
      </div>
    </PlayAudioStyled>
  );
};

export default PlayAudio;
