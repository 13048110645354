import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { CategoryStyled, ItemStyled } from './styled';
// import { Blocks, Typographys } from 'components';
import { Blocks, Typhograpys } from 'components'
import InfiniteScroll from 'react-infinite-scroll-component';

const Category = ({ theme_standard, data, onClickItem, onClickEdit, hasMore, fetchMoreData }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [active, setActive] = useState(0);

  const handleClickItem = (key, value) => {
    setActive(key);
    onClickItem && onClickItem(value);
  };

  return (
    <CategoryStyled>
      <div className={customClass}>
        <Blocks.Box
          theme_standard_box
          paddingTop="13px"
          paddingRight="13px"
          paddingBottom="13px"
          paddingLeft="13px"
        >
          <div className="cate_head">
            <Typhograpys.TopBottom
              theme_left_align
              label="หมวด"
              labelBottom="Category"
            />
            <div className='edit_label' onClick={onClickEdit}>
              แก้ไข
            </div>
          </div>
          <div className='cate_body'>
            {data && data.length > 0 ?
              <InfiniteScroll
                dataLength={data.length}
                next={fetchMoreData}
                className='infinit_body'
                hasMore={hasMore}
                loader={<h3 className="loading">Loading...</h3>}
                style={{ maxHeight: '430px' }}
                height={'100%'}
                endMessage={
                  <p className="loading">
                    {/* <b>ดูครบทั้งหมดแล้ว</b> */}
                  </p>
                }
              >
                {data.map((e, i) => (
                  <ItemStyled
                    className={active === i ? 'active' : ''}
                    key={i}
                    onClick={() => handleClickItem(i, e)}
                  >
                    <div className="item_txt_left_col">
                      {/* <div className="item_icon">{e.icon}</div> */}
                      <div className="item_txt_group">
                        <div className="cate_title">{e.title}</div>
                        {/* <div className="cate_sub_title">{e.title}</div> */}
                      </div>
                    </div>
                    <div className="item_txt_right_col">{e.score}</div>
                  </ItemStyled>
                ))}
              </InfiniteScroll>
              :
              <div className='no_data'>
                No Data
              </div>
            }
          </div>
        </Blocks.Box>
      </div>
    </CategoryStyled>
  );
};

Category.propTypes = {};

export default Category;
