import styled from 'styled-components';

export const NavbarStyled = styled.div`
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  -webkit-box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.16);
  height: 64px;
  padding: 0 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .nav_left {
    .nav_logo {
      width: 112px;
      height: auto;
    }
  }
  .nav_right {
    display: flex;
    .menu_list_row {
      margin-right: 120px;
      display: flex;
      column-gap: 64px;
      .menu_item {
        border-bottom: 3px solid ${({ theme }) => theme.COLORS.WHITE_1};
        padding: 6px 16px;
        cursor: pointer;
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        &.active {
          border-color: ${({ theme }) => theme.COLORS.GREEN_1};
          color: ${({ theme }) => theme.COLORS.GREEN_1};
        }
      }
    }
    .avatar_info {
      display: flex;
      align-items: center;
      column-gap: 23px;
      .nav_logout {
        height: 19.5px;
        cursor: pointer;
      }
    }
  }
`;
