import styled from 'styled-components';

export const AvatarNameStyled = styled.div`
  .avatar_row {
    display: flex;
    align-items: center;
    column-gap: 9px;
    .avatar_img {
      width: 31px;
      height: 31px;
      object-fit: cover;
    }
    .avatar_name {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_4};
    }
  }

  .theme_standard {
  }
`;
