import { useState, useEffect } from 'react';
import { NavbarStyled } from './styled';
import { useSelector } from 'react-redux';
import { userService } from 'apiService';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Displays, Icons } from 'components';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationSplit = location.pathname.split('/');
  const { authenRedux } = useSelector((state) => state);

  const [_active, _setActive] = useState('video');

  useEffect(() => {
    _setActive(locationSplit[2]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleClickLogout = async () => {
    let res = await userService.GET_LOGOUT();
    if (res && res.success) {
      localStorage.clear();
      navigate('/');
    }
  };

  const _handleClickMenu = (e) => {
    _setActive(e.key);
    navigate(e.path);
  };

  return (
    <NavbarStyled>
      <div className="nav_left">
        <img
          className="nav_logo"
          alt="herwill logo"
          src="/assets/images/icons/herwill_logo.png"
        />
      </div>
      <div className="nav_right">
        <div className="menu_list_row">
          {menuList &&
            menuList.map((e, i) => (
              <div
                key={i}
                className={`menu_item ${e.key === _active ? 'active' : ''}`}
                onClick={() => _handleClickMenu(e)}
              >
                {e.label}
              </div>
            ))}
        </div>
        <div className="avatar_info">
          <Displays.AvatarName
            label={`${authenRedux.firstname} ${authenRedux.lastname}`}
          />
          <div className="nav_logout" onClick={_handleClickLogout}>
            <Icons.Logout />
          </div>
        </div>
      </div>
    </NavbarStyled>
  );
};

const menuList = [
  {
    key: 'video',
    label: 'วิดีโอ',
    path: ROUTE_PATH.VIDEO,
  },
  {
    key: 'content',
    label: 'บทความ',
    path: ROUTE_PATH.CONTENT,
  },
];

export default Navbar;
