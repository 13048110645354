import styled from 'styled-components';

export const CategoryStyled = styled.div`
  .cate_head {
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
    .edit_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .cate_body {
    max-height: 538px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
    width: 100%;
    .no_data {
      text-align: center;
    }
    .loading {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      text-align: center;
    }
    .infinit_body {
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
    }
  }
 
  .theme_standard {
  }
`;

export const ItemStyled = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: flex-start;
  padding: 10px 17px;
  cursor: pointer;
  box-sizing: border-box;
  .item_txt_left_col {
    display: flex;
    align-items: center;
    .item_icon {
      height: 32px;
    }
    .item_txt_group {
      margin-left: 16px;
      .cate_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        margin-bottom: 6px;
      }
      .cate_sub_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_4};
      }
    }
  }
  .item_txt_right_col {
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLACK_5};
    padding-left: 5px;
  }
  &.active {
    background: ${({ theme }) => theme.COLORS.GRAY_5};
    border-radius: 5px;
    box-shadow:  3px 4px 8px -5px rgb(0 0 0 / 46%);
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_4};
    .item_txt_left_col {
      .item_txt_group {
        .cate_title {
          color: ${({ theme }) => theme.COLORS.BLACK_1};
        }
      }
    }
    .item_txt_right_col {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }
  }
`;
