import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnLinkStyled } from './styled';

const BtnLink = ({ theme_standard_btn_link, label, svg, onClick }) => {
  const customClass = cx({
    theme_standard_btn_link: theme_standard_btn_link,
  });
  return (
    <BtnLinkStyled>
      <div className={customClass} onClick={onClick}>
        {svg && <div className="svg_show">{svg}</div>}
        <div className="show_label">{label}</div>
      </div>
    </BtnLinkStyled>
  );
};

BtnLink.propTypes = {};

export default BtnLink;
