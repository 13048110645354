import styled from 'styled-components';

export const BtnLinkStyled = styled.div`
  .theme_standard_btn_link {
    width: fit-content;
    text-align: center;
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    font-size: ${({ theme }) => theme.FONT.SIZE.S10};
    cursor: pointer;
    .svg_show {
    }
    .show_label {
      margin-top: 4px;
    }
  }
`;
