import { ShowImageStyled } from './styled';

const ShowImage = ({ url }) => {
  return (
    <ShowImageStyled>
      <div className='container_image'>
        <div className='image_location'>
          <div className='body_image' style={{ backgroundImage: `url(${url})` }} />
        </div>
      </div>
    </ShowImageStyled>
  );
};

export default ShowImage;
