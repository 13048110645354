import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BoxStyled } from './styled';

const Box = ({
  theme_standard_box,
  theme_standard_box_white,
  theme_gray_box,
  children,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  borderRadius,
  height,
}) => {
  const customClass = cx({
    theme_standard_box: theme_standard_box,
    theme_standard_box_white: theme_standard_box_white,
    theme_gray_box: theme_gray_box,
  });
  return (
    <BoxStyled
      paddingTop={paddingTop}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      borderRadius={borderRadius}
      height={height}
    >
      <div className={customClass}>{children}</div>
    </BoxStyled>
  );
};

Box.propTypes = {};

export default Box;
