import React from 'react';
import { connect } from 'react-redux';
import { VideoCategoryContainerStyled } from './styled';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { withRouter } from 'navigations/withRouter';
import { AlertControl } from 'components/Modals/Alert';
import { CategoryCreateContainer, CategoryManageContainer } from './Modals';
import { CategoryDragDropWidgets } from 'widgets';
import { Displays, Icons, Buttons, Tables, Modals } from 'components';
import { userService } from 'apiService';
import { toast } from 'react-toastify';

class VideoCategoryContainer extends React.Component {
  state = { isLoading: true, selectedHomeCateData: [] };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await Promise.all([
      this.fetchHomeCategory(),
      this.fetchCategory(),
    ]);
    if (res) {
      this.setState({
        isLoading: false,
        homeCateList: [...res[0]],
        cateList: [...res[1].data],
        cateListPagination: res[1].page,
      });
    }
  };

  fetchHomeCategory = async () => {
    let res = await userService.GET_HOME_CATEGORY_LIST();
    if (res && res.success) {
      return res.data;
    }
  };

  fetchCategory = async (stringQuery = `?per_page=10&offset=0`, isSetData) => {
    let res = await userService.GET_CATEGORY_LIST(stringQuery);
    if (res && res.success) {
      if (isSetData) {
        this.setState({
          cateList: res.data,
          cateListPagination: res.page,
        });
      }
      return res;
    }
  };

  handleBack = () => {
    this.props.router.navigate(`${ROUTE_PATH.VIDEO}`);
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
      renderModal: false,
    });
  };

  handleEnbModal = (key, value) => {
    const { cateList, homeCateList, selectedCateData } = this.state;
    switch (key) {
      case 'management_category':
        this.setState({
          isShowModal: true,
          renderModal: (
            <CategoryManageContainer
              homeCateList={homeCateList}
              dataCateList={cateList}
              onClose={this.handleCloseModal}
              onSuccess={() => {
                this.setState({
                  isLoading: true,
                });
                this.fetchData();
                this.handleCloseModal();
              }}
            />
          ),
        });
        break;
      case 'create_category':
        this.setState({
          isShowModal: true,
          renderModal: (
            <CategoryCreateContainer
              data={
                value === 'create'
                  ? false
                  : cateList.find(
                      (e) =>
                        e.uuid === selectedCateData[selectedCateData.length - 1]
                    )
              }
              onClose={this.handleCloseModal}
              onSuccess={() => {
                this.setState({
                  isLoading: true,
                });
                this.fetchData();
                this.handleCloseModal();
              }}
            />
          ),
        });
        break;
      default:
        break;
    }
  };

  // SEC1 HOME CATEGORY
  // ========================================
  // ========================================
  handleClickDeleteManageCate = () => {
    AlertControl.show({
      title: 'ยืนยันการลบหมวดวิดีโอในหน้า Home หรือไม่',
      description: 'คุณไม่สามารถกู้คืนรายการของคุณได้ หลังจากที่คุณลบไปแล้ว',
      btnLabel1: 'ยืนยัน',
      btnLabel2: 'ยกเลิก',
      onEvent1: () => {
        this.handleDeleteHomeCategory();
      },
    });
  };
  handleSelectedHomeCate = (e) => {
    let temp = [...this.state.selectedHomeCateData];
    if (temp.length === 0) {
      temp.push(e);
    } else {
      let findIndex = temp.findIndex((f) => f.uuid === e.uuid);
      if (findIndex !== -1) {
        temp.splice(findIndex, 1);
      } else {
        temp.push(e);
      }
    }
    this.setState({
      selectedHomeCateData: temp,
    });
    // this.setState({
    //   selectedHomeCateData: Object.keys(e).map((key) => key),
    // });
  };
  handleDeleteHomeCategory = async () => {
    const { selectedHomeCateData, homeCateList } = this.state;
    if (selectedHomeCateData && selectedHomeCateData.length > 0) {
      this.setState({
        isLoading: true,
      });
      let tempHomeList = homeCateList;
      selectedHomeCateData.forEach((e) => {
        let findIndex = tempHomeList.findIndex((f) => f.uuid === e.uuid);
        if (findIndex !== -1) {
          tempHomeList.splice(findIndex, 1);
        }
      });
      let params = {
        categories: tempHomeList.map((e, i) => ({
          uuid: e.uuid,
          order: i + 1,
        })),
      };

      let res = await userService.PUT_HOME_CATEGORY_DETAIL(params);
      if (res && res.success) {
        toast.success('ยืนยันสำเร็จ');
        this.fetchData();
      }
    }
  };

  // SEC2 CATEGORY
  // ========================================
  // ========================================
  handlePageClick = async (e) => {
    let query = `?per_page=10&offset=${e.selected * 10}`;
    this.fetchCategory(query, true);
  };
  handleChangeSelectedCategoryTable = (e) => {
    this.setState({
      selectedCateData: Object.keys(e).map((key) => key),
    });
  };
  handleConfirmDeleteCategory = () => {
    AlertControl.show({
      title: 'ยืนยันการลบหมวดวิดีโอของคุณหรือไม่',
      description: 'คุณไม่สามารถกู้คืนรายการของคุณได้ หลังจากที่คุณลบไปแล้ว',
      btnLabel1: 'ยืนยัน',
      btnLabel2: 'ยกเลิก',
      onEvent1: () => {
        this.handleDeleteCategory();
      },
    });
  };
  handleDeleteCategory = async () => {
    const { selectedCateData } = this.state;
    this.setState({
      isLoading: true,
    });
    Promise.all(
      selectedCateData.map((e) => {
        return new Promise(async (resolve) => {
          let res = await userService.DELETE_CATEGORY_DETAIL(e);
          if (res && res.success) {
            resolve(true);
          }
        });
      })
    ).then(async () => {
      toast.success('ลบสำเร็จ');
      this.fetchData();
    });
  };

  handleBackClick = () => {
    this.props.router.navigate(`${ROUTE_PATH.VIDEO}`);
  };

  render() {
    const {
      isLoading,
      isShowModal,
      renderModal,
      homeCateList,
      selectedHomeCateData,
      cateList,
      cateListPagination,
      selectedCateData,
    } = this.state;
    return (
      <VideoCategoryContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Displays.Loading theme_standard_loading />
          </div>
        ) : (
          <div className="content_layout_show">
            <Buttons.BtnLink
              theme_standard_btn_link
              svg={<Icons.ArrowLeft />}
              label="ย้อนกลับ"
              onClick={this.handleBackClick}
            />
            <div className="title_show">หมวดวิดีโอทั้งหมด</div>
            <div className="group_head_table">
              <div className="box_title">
                <div className="svg_edit">
                  <Icons.Content />
                </div>
                <div>หมวดวิดีโอที่โชว์บนหน้า home</div>
              </div>
              <div className="group_btn">
                <div className="btn_del">
                  <Buttons.BtnPrefixIcon
                    theme_opacity
                    icon={
                      <Icons.Delete3
                        color={
                          selectedHomeCateData &&
                          selectedHomeCateData.length > 0
                            ? '#CF1A71'
                            : '#676767'
                        }
                        color2={
                          selectedHomeCateData &&
                          selectedHomeCateData.length > 0
                            ? '#fafafa'
                            : '#c1beb9'
                        }
                      />
                    }
                    fontSize="18px"
                    fontFamily="kanit_bold"
                    label="Delete"
                    onClick={this.handleClickDeleteManageCate}
                    disabled={
                      !(selectedHomeCateData && selectedHomeCateData.length > 0)
                    }
                  />
                </div>
                <div className="btn_add">
                  <Buttons.BtnPrefixIcon
                    theme_red
                    fontSize="16px"
                    fontFamily="kanit_medium"
                    label="เลือกหมวดที่ไปโชว์บนหน้า home"
                    onClick={() => this.handleEnbModal('management_category')}
                  />
                </div>
              </div>
            </div>
            <div className="table_show">
              <CategoryDragDropWidgets
                data={homeCateList}
                onSelected={this.handleSelectedHomeCate}
              />
            </div>
            <div className="group_head_table mt">
              <div className="box_title">
                <div className="svg_edit">
                  <Icons.Content />
                </div>
                <div>หมวดวิดีโอทั้งหมด</div>
              </div>
              <div className="group_btn">
                <div className="btn_del">
                  <Buttons.BtnPrefixIcon
                    theme_opacity
                    icon={
                      <Icons.Delete3
                        color={
                          selectedCateData && selectedCateData.length > 0
                            ? '#CF1A71'
                            : '#676767'
                        }
                        color2={
                          selectedCateData && selectedCateData.length > 0
                            ? '#fafafa'
                            : '#c1beb9'
                        }
                      />
                    }
                    fontSize="18px"
                    fontFamily="kanit_bold"
                    label="Delete"
                    onClick={this.handleConfirmDeleteCategory}
                    disabled={
                      !(selectedCateData && selectedCateData.length > 0)
                    }
                  />
                </div>
                <div className="btn_add">
                  <Buttons.BtnPrefixIcon
                    theme_red_border
                    fontSize="16px"
                    fontFamily="kanit_medium"
                    label="แก้ไขชื่อหมวดวิดีโอ"
                    iconBack={
                      <Icons.Edit
                        color={
                          selectedCateData && selectedCateData.length > 0
                            ? '#CF1A71'
                            : '#676767'
                        }
                        color2={
                          selectedCateData && selectedCateData.length > 0
                            ? '#fafafa'
                            : '#c1beb9'
                        }
                      />
                    }
                    disabled={
                      !(selectedCateData && selectedCateData.length > 0)
                    }
                    onClick={() =>
                      this.handleEnbModal('create_category', 'edit')
                    }
                  />
                </div>
                <div className="btn_add">
                  <Buttons.BtnPrefixIcon
                    theme_red
                    fontSize="16px"
                    fontFamily="kanit_medium"
                    label="สร้างหมวดหมู่"
                    onClick={() =>
                      this.handleEnbModal('create_category', 'create')
                    }
                  />
                </div>
              </div>
            </div>
            <div className="table_show">
              <Tables.TableAction
                theme_standard_table_action_pink
                minHeight="674px"
                fieldSelect="uuid"
                columns={columns2({
                  // handleClickDetail: this.handleClickDetail,
                })}
                data={cateList}
                pagination={
                  cateList && cateList.length > 0 && cateListPagination
                }
                labelEmpty="ยังไม่มีข้อมูล"
                showPagination
                handlePageClick={this.handlePageClick}
                onChangeSelected={this.handleChangeSelectedCategoryTable}
              />
            </div>
          </div>
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </VideoCategoryContainerStyled>
    );
  }
}

const columns2 = ({ handleClickDetail }) => [
  {
    title: '',
    field: '',
    headerStyle: { textAlign: 'center', width: '20px', flexShrink: 0 },
    style: { textAlign: 'center', width: '20px', flexShrink: 0 },
  },
  {
    title: 'หัวข้อ',
    field: 'name_th',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div
        style={{
          color: '#000000',
          overflow: 'hidden',
          maxWidth: '300px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        onClick={() => handleClickDetail(origin)}
      >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <div>จำนวนวิดีโอ</div>
      </div>
    ),
    field: 'contents_count',
    headerStyle: {
      display: 'flex',
      justifyContent: 'center',
      width: '100px',
      flexShrink: 0,
    },
    style: {
      display: 'flex',
      justifyContent: 'center',
      width: '100px',
      flexShrink: 0,
    },
    render: ({ origin, value }) => (
      <div
        style={{ fontSize: '18px', color: '#000000' }}
        onClick={() => handleClickDetail(origin)}
      >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <div>จำนวนการเข้าดู</div>
      </div>
    ),
    field: 'total_view_count',
    headerStyle: {
      display: 'flex',
      justifyContent: 'center',
      width: '140px',
      flexShrink: 0,
    },
    style: {
      display: 'flex',
      justifyContent: 'center',
      width: '140px',
      flexShrink: 0,
    },
    render: ({ origin, value }) => (
      <div
        style={{ fontSize: '18px', color: '#000000' }}
        onClick={() => handleClickDetail(origin)}
      >
        {value}
      </div>
    ),
  },
];

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VideoCategoryContainer));
