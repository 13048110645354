import styled from 'styled-components'

export const ShowImageStyled = styled.div`
width: 100%;
  .container_image {
    width: 100%;
    .image_location {
      width: 100%;
      .body_image {
        border-radius: 12px;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        height: 216px;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
`
