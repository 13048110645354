import styled from 'styled-components'

export const PlayVideoStyled = styled.div`
    width: 100%;
    .video_wrap {
        cursor: pointer;
        position: relative;
        display: flex;
        overflow: hidden;
        border-radius: 10px;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);    
          
        .react-player {
            
        }
    }
`
