import styled from 'styled-components';

export const InputTextAreaStyled = styled.div`
  .error {
    margin-top: 3px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    text-align: left;
  }
  .theme_standard_text_area {
    position: relative;
    .title_layout {
      display: flex;
      justify-content: ${({ flexStyle }) =>
        flexStyle ? flexStyle : 'space-between'};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLUE_4};
      align-items: center;
      .show_title {
        display: flex;
        margin-bottom: 12px;
        .title_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
          color: ${({ theme }) => theme.COLORS.BLACK_4};
          margin-right: 9px;
        }
        .sub_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_6};
          padding-top: 4px;
        }
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        color: ${({ theme }) => theme.COLORS.GRAY_6};
      }
    }
    .icon {
      position: absolute;
      top: 59px;
      left: 23px;
    }
    .text_area {
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      border: 2px solid ${({ theme }) => theme.COLORS.PINK_2};
      resize: none;
      outline: none;
      padding: 14px 20px;
      height: ${({ height }) => (height ? height : '140px')};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${(props) =>
        props.fontFamily ? props.fontFamily : 'kanit_regular'};
      color: ${(props) =>
        props.fontColor ? props.fontColor : ({ theme }) => theme.COLORS.BLACK_1};
      ::placeholder {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_4};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        padding-left: ${({ svg }) => (svg ? '40px' : '0px')};
      }
    }
  }
`;
