import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnIconStyled } from './styled';

const BtnIcon = ({ theme_standard_btn_icon, svg, onClick }) => {
  const customClass = cx({
    theme_standard_btn_icon: theme_standard_btn_icon,
  });
  return (
    <BtnIconStyled>
      <div className={customClass} onClick={onClick}>
        {svg}
      </div>
    </BtnIconStyled>
  );
};

BtnIcon.propTypes = {};

export default BtnIcon;
