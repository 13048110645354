import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputTagStyled } from './styled';
import { WithContext as ReactTags } from 'react-tag-input';
import { userService } from 'apiService';

// const suggestions = COUNTRIES.map((country) => {
//   return {
//     id: country,
//     text: country,
//   };
// });

const KeyCodes = {
  comma: 188,
  enter: 13,
};

// const delimiters = [KeyCodes.comma, KeyCodes.enter];

const delimiters = [KeyCodes.enter];

const InputTag = ({ theme_standard, value, onChange, errors }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  let refSettimeout = useRef();

  const [_suggestions, _setSuggestions] = useState();
  const [tags, setTags] = useState([
    // { id: 'Thailand', text: 'Thailand' },
    // { id: 'India', text: 'India' },
    // { id: 'Vietnam', text: 'Vietnam' },
    // { id: 'Turkey', text: 'Turkey' },
  ]);

  useEffect(() => {
    if (value && value !== tags) {
      setTags(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    onChange && onChange(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    let find = tags.find((e) => e.text === tag.text);
    if (!find) {
      setTags([...tags, tag]);
      onChange && onChange([...tags, tag]);
    }
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const _onChange = (e) => {
    clearTimeout(refSettimeout.current);
    if(e) {
      refSettimeout.current = setTimeout(async () => {
        let res = await userService.GET_CONTENT_TAGS_LIST(`?keyword=${e}`);
        if (res && res.success) {
          _setSuggestions(
            res && res.data.length > 0
              ? res.data.map((e) => ({ id: e.uuid, text: e.tag }))
              : []
          );
        }
      }, 400);
    }
  };

  return (
    <InputTagStyled>
      <div className={customClass}>
        <ReactTags
          classNames={{
            tagInputField: 'tagInputFieldClass',
            suggestions: 'suggestionsClass',
            activeSuggestion: 'activeSuggestionClass',
          }}
          tags={tags}
          suggestions={_suggestions}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          inputFieldPosition="bottom"
          handleInputChange={_onChange}
          // autocomplete
          maxLength={50}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </InputTagStyled>
  );
};

InputTag.propTypes = {};

export default InputTag;
