import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputGroupCheckBox2Styled } from './styled';
import { Buttons, Icons } from 'components';

const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
};

const InputGroupCheckBox2 = ({
  theme_standard_group_check_box,
  data,
  onChangeCustom,
  labelButton,
  titleGroupCheckBox,
  fontSize,
  fontFamily,
  fontColor,
  boxSize,
  topBox,
  rightBox,
  color,
}) => {
  const customClass = cx({
    theme_standard_group_check_box: theme_standard_group_check_box,
  });

  const [seleted, setSelected] = useState({});
  const [disabled, setDisabled] = useState(false);
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    if (Object.keys(seleted).length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [seleted]);

  const handleSelected = (key) => {
    let selectedTemp = { ...seleted };
    if (selectedTemp[key]) {
      if (key === 'all') {
        selectedTemp = {};
      } else {
        if (selectedTemp['all']) {
          delete selectedTemp['all'];
        }
        delete selectedTemp[key];
      }
    } else {
      if (key === 'all') {
        data.forEach((e) => {
          selectedTemp[e.value] = true;
        });
      } else {
        selectedTemp[key] = true;
      }
    }
    setSelected({ ...selectedTemp });
  };

  const handleClickSelected = () => {
    setIsComponentVisible(false);
    onChangeCustom && onChangeCustom(Object.keys(seleted).map((key2) => key2));
  };

  const handleClickFilter = () => {
    setIsComponentVisible(true);
  };

  return (
    <InputGroupCheckBox2Styled
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontColor={fontColor}
      boxSize={boxSize}
      topBox={topBox}
      rightBox={rightBox}
    >
      <div className={customClass} ref={ref}>
        <div className="icon_filter" onClick={handleClickFilter}>
          <Icons.Control2 color={color && color}
          />
        </div>
        <div
          className="filter_block"
          style={{ visibility: isComponentVisible ? 'visible' : 'hidden' }}
        >
          <div className='box_display'>
            <div className='top_filter'>
              <div>
                Filter :
              </div>
              <div className='label_top_filter'>
                {titleGroupCheckBox}
              </div>
            </div>
            {data &&
              data.map((e, i) => (
                <div key={i + 1} className="body_checkbox">
                  <label className="container">
                    <input
                      type="checkbox"
                      name={e.name}
                      // value={seleted[e.value]}
                      checked={seleted[e.value]}
                      onChange={() => {
                        handleSelected(e.value);
                      }}
                    />
                    <span className="checkmark"></span>
                    <div className='show_label'>
                      {e.label}
                    </div>
                  </label>
                </div>
              ))}
            <div className="btn_show">
              <Buttons.BtnPrefixIcon
                theme_blue
                fontSize='14px'
                label={labelButton}
                disabled={disabled}
                onClick={handleClickSelected}
              />
            </div>
          </div>


          {/* <Box theme_standard_box>
            {data &&
              data.map((e, i) => (
                <div key={i + 1} className="body_checkbox">
                  <label className="container">
                    <input
                      type="checkbox"
                      name={e.name}
                      // value={seleted[e.value]}
                      checked={seleted[e.value]}
                      onChange={() => {
                        handleSelected(e.value);
                      }}
                    />
                    <span className="checkmark"></span>
                    <div className='show_label'>
                      {e.label}
                    </div>
                  </label>
                </div>
              ))}
            <div className="btn_show">
              <Buttons.BtnNormal
                theme_standard_btn_normal_pink
                label={labelButton}
                borderRadius="6px"
                fontSize="14px"
                disabled={disabled}
                onClick={handleClickSelected}
              />
            </div>
          </Box> */}
        </div>
      </div>
    </InputGroupCheckBox2Styled>
  );
};

InputGroupCheckBox2.propTypes = {};

export default InputGroupCheckBox2;
