import React from 'react';
// import { connect } from 'react-redux';
import { CategoryCreateContainerStyled } from './styled';
import { userService } from 'apiService';
import { toast } from 'react-toastify';
import { Icons, Forms } from 'components';

class CategoryCreateContainer extends React.Component {
  state = {};

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.setState({
        initialValues: {
          name_th: data.name_th,
        },
      });
    }
  }

  handleSubmit = async (values) => {
    const { onSuccess, data } = this.props;
    let params = {
      ...values,
    };
    if (!data) {
      let res = await userService.POST_CATEGORY_CREATE(params);
      if (res && res.success) {
        toast.success('สร้างสำเร็จ');
        onSuccess && onSuccess();
      }
    } else {
      let res = await userService.PATCH_CATEGORY_DETAIL(data.uuid, params);
      if (res && res.success) {
        toast.success('สร้างสำเร็จ');
        onSuccess && onSuccess();
      }
    }
  };

  render() {
    const { onClose } = this.props;
    const { initialValues } = this.state;
    return (
      <CategoryCreateContainerStyled>
        <div className="icon_close" onClick={onClose}>
          <Icons.Close />
        </div>
        <div className="cm_title">สร้างหมวดหมู่</div>
        <Forms.CategoryForm
          initialValues={initialValues}
          onCancel={onClose}
          onSubmit={this.handleSubmit}
        />
      </CategoryCreateContainerStyled>
    );
  }
}

export default CategoryCreateContainer;
