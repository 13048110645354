import { PlayVideoStyled } from './styled';
import ReactPlayer from 'react-player';
import React from 'react';

const PlayVideo = ({ url }) => {
  return (
    <PlayVideoStyled>
      <div className="video_wrap" >
        <ReactPlayer
          className='react-player'
          url={url}
          width="100%"
          // height="100%"
          controls
          muted
        />
      </div>
    </PlayVideoStyled>
  );
};

export default PlayVideo;
