export default function renderIcon({ color = '#cf1a71' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.952"
      height="11.57"
      viewBox="0 0 12.952 11.57"
    >
      <g
        id="Group_86188"
        data-name="Group 86188"
        transform="translate(-328.277 -56.924)"
      >
        <path
          id="Rectangle"
          d="M1.251.469,13.542,0A1.035,1.035,0,0,1,14.713.99a1.242,1.242,0,0,1-1.25,1.081L1.172,2.538A1.035,1.035,0,0,1,0,1.549,1.242,1.242,0,0,1,1.251.469Z"
          transform="translate(328.367 66.577) rotate(-41)"
          fill={color}
        />
        <path
          id="Rectangle-2"
          data-name="Rectangle"
          d="M1.159,0,13.374.486a1.281,1.281,0,0,1,1.247,1.166,1.057,1.057,0,0,1-1.158,1.07L1.248,2.237A1.281,1.281,0,0,1,0,1.071,1.057,1.057,0,0,1,1.159,0Z"
          transform="translate(330.027 56.966) rotate(40)"
          fill={color}
        />
      </g>
    </svg>
  );
}
