import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { LoadingStyled } from './styled';
import { Puff } from 'react-loader-spinner';

const Loading = ({
  theme_standard_loading,
  color = '#CF1A71',
  height = 70,
  width = 70,
}) => {
  const customClass = cx({
    theme_standard_loading: theme_standard_loading,
  });
  return (
    <LoadingStyled>
      <div className={customClass}>
        <Puff width={width} height={height} color={color} />
      </div>
    </LoadingStyled>
  );
};

Loading.propTypes = {};

export default Loading;
