import styled from 'styled-components';

export const CategoryCreateContainerStyled = styled.div`
  position: relative;
  border-radius: 1px solid ${({ theme }) => theme.COLORS.PINK_1};
  border-radius: 14px;
  padding: 56px 34px 48px 34px;
  width: 100%;
  max-width: 805px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .icon_close {
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
  }
  .cm_title {
    margin-bottom: 40px;
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S22};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_SEMIBOLD};
    color: ${({ theme }) => theme.COLORS.BLACK_6};
  }
`;
