import styled from 'styled-components';

export const CategoryItemStyled = styled.div`
  .cate_item_container {
    display: flex;
    align-items: center;
    column-gap: 18px;
    cursor: pointer;
    .c_no {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_BOLD};
      color: ${({ theme }) => theme.COLORS.PINK_1};
    }
    .c_panel_row {
      flex: 1;
      border-radius: 14px;
      -webkit-box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.16);
      padding: 12px 16px;
      display: flex;
      align-items: center;
      background: ${({ disabled, theme }) =>
        disabled ? `${theme.COLORS.PINK_1}1A` : theme.COLORS.WHITE_1};
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      column-gap: 12px;
      .c_drag_drop_wrap {
        height: 19px;
        cursor: move;
      }
      .c_cate_name {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
    }
  }

  .theme_standard {
  }
`;
