import styled from 'styled-components';

export const ContentDetailContainerStyled = styled.div`
    
    .show_loading {
        height: calc(100vh - 189px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .box_layout {
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .layer_one {
            display: flex;
            margin-top: 20px;
            padding-bottom: 20px;
            justify-content: space-between;
            .box_title {
                display: flex;
                align-items: center;
                font-size: ${({ theme }) => theme.FONT.SIZE.S24};
                font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
                color: ${({ theme }) => theme.COLORS.BLACK_4};
                .svg_edit {
                  display: flex;
                  margin-right: 12px;
                }
            }
            .btn_edit {
                width: 192px;
            }
        }
        .box_detail {
            padding: 32px;
            border-radius: 18px;
            border: 1px solid ${({ theme }) => theme.COLORS.PINK_2};
            .box_shadow {
                background: ${({ theme }) => theme.COLORS.WHITE_1};
                padding: 26px;
                display: flex;
                border-radius: 10px;
                box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
                .img_show {
                    width: 35%;
                    border-radius: 12px;
                    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                    background-image: url(${({ src }) => src});
                    height: 277px;
                    object-fit: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    flex-shrink: 0;
                    // width: 408px;
                    height: 277px;
                }
                .blank_img {
                    width: 35%;
                    border-radius: 12px;
                    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                    background: ${({ theme }) => theme.COLORS.PINK_3};
                    height: 277px;
                    object-fit: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    flex-shrink: 0;
                    // width: 408px;
                    height: 277px;
                }
                .group_label {
                    margin-left: 29px;
                    width: 63%;
                    display: flex;
                    flex-direction: column;
                    row-gap: 32px;
                    .big_text {
                        font-size: ${({ theme }) => theme.FONT.SIZE.S24};
                        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_SEMIBOLD};
                        color: ${({ theme }) => theme.COLORS.RED_2};
                        display: flex;
                        justify-content: space-between;
                        .status_show {
                            display: flex;
                            align-items: center;
                            column-gap: 23px;
                            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
                            font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
                            color: ${({ theme }) => theme.COLORS.BLACK_1};
                            .svg_status {
                                display: flex;
                            }
                        }
                    }
                    .mid_text {
                        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
                        color: ${({ theme }) => theme.COLORS.GRAY_8};
                    }
                    .small_text {
                        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
                        color: ${({ theme }) => theme.COLORS.BLACK_4};
                        padding: 10px;
                        border-radius: 6px;
                        background: ${({ theme }) => theme.COLORS.GRAY_8}1F;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
`;
