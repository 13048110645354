import React, { useState, useEffect } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { UploadSetImageStyled } from './styled';
import { Icons, Buttons } from 'components';
import { useDropzone } from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

const UploadSetImage = ({
  theme_upload_set_image,
  heightBox,
  acceptType,
  value,
  onChange,
  maxMBFileSizeWarning,
  width,
  name,
  errors,
}) => {
  const customClass = cx({
    theme_upload_set_image: theme_upload_set_image,
  });
  const [files, setFiles] = useState([]);
  const [initial, setInitial] = useState(true);
  const [haveDelete, _setHaveDelete] = useState(false);
  const [isShowLoadMore, setIsShowLoadMore] = useState(false);

  const renderAcceptType = (type) => {
    switch (type) {
      case 'cover':
        return {
          'image/jpeg': ['.jpeg', '.png'],
        };
      case 'audio':
        return '.mp3,audio/mpeg3';
      case 'video':
        return 'video/*';
      default:
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { ...renderAcceptType(acceptType) },
    onDrop: async (acceptedFiles) => {
      if (maxMBFileSizeWarning) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(2)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          alert('ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล');
        }
      }
      const imageResize = await resizeFile(acceptedFiles);
      let fileTemp = [
        ...files,
        ...imageResize.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isNewUpload: true,
            fieldName: name,
          })
        ),
      ];
      setIsShowLoadMore(false);
      setInitial(false);
      setFiles(fileTemp);
      onChange && onChange(fileTemp);
    },
  });

  const resizeFile = (files) => {
    return Promise.all(
      files.map((e) => {
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            e,
            1024,
            1024,
            'jpeg',
            90,
            0,
            (uri) => {
              resolve(uri);
            },
            'file'
          );
        });
      })
    ).then((e) => {
      return e;
    });
  };

  useEffect(() => {
    if (value && value.length > 0 && initial) {
      setFiles(value);
      // _setHaveDelete(true)
      if (value.length > 3) {
        setIsShowLoadMore(true);
        setInitial(false);
      }
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickRemove = (index, data) => {
    if (data.isNewUpload) {
      let filesTemp = files;
      filesTemp.splice(index, 1);
      setFiles([...filesTemp]);
    } else {
      let filesTemp = files;
      filesTemp[index].isDelete = true;
      filesTemp[index].fieldName = name;
      setFiles([...filesTemp]);
      _setHaveDelete(true);
    }
  };

  const handleClickMore = () => {
    setIsShowLoadMore(false);
  };

  return (
    <UploadSetImageStyled heightBox={heightBox} width={width}>
      <div className={customClass}>
        {/* {data &&
          data.map((e, i) => (
            <div
              key={i + 1}
              className="show_img"
              style={{ backgroundImage: `url(${e.url})` }}
            >
              <div className="close">
                <Icons.CloseX />
              </div>
            </div>
          ))} */}
        {/* <div className="container_other">
          <div
            className="show_other"
            style={{ backgroundImage: `url(${data[0].url})` }}
          />
          <div className="num_show">+ 5</div>
        </div> */}
        {files && files.length > 0 && !isShowLoadMore
          ? files.map((e, i) =>
              e.isNewUpload ? (
                <div
                  key={i + 1}
                  className="show_img"
                  style={{ backgroundImage: `url(${e.preview})` }}
                >
                  <div className="close" onClick={() => onClickRemove(i, e)}>
                    <Icons.CloseX />
                  </div>
                  {isShowLoadMore && i === 2 && (
                    <div className="block_more" onClick={handleClickMore}>
                      <div className="num_show">+ 5</div>
                    </div>
                  )}
                </div>
              ) : (
                !e.isDelete && (
                  <div
                    key={i + 1}
                    className="show_img"
                    style={{ backgroundImage: `url(${e.media_uri})` }}
                  >
                    <div className="close" onClick={() => onClickRemove(i, e)}>
                      <Icons.CloseX />
                    </div>
                    {isShowLoadMore && i === 2 && (
                      <div className="block_more" onClick={handleClickMore}>
                        <div className="num_show">+ 5</div>
                      </div>
                    )}
                  </div>
                )
              )
            )
          : files.slice(0, 3).map((e, i) =>
              e.isNewUpload ? (
                <div
                  key={i + 1}
                  className="show_img"
                  style={{ backgroundImage: `url(${e.preview})` }}
                >
                  <div className="close" onClick={() => onClickRemove(i, e)}>
                    <Icons.CloseX />
                  </div>
                  <div className="block_more" onClick={handleClickMore}>
                    <div className="num_show">+ 5</div>
                  </div>
                  {isShowLoadMore && i === 2 && (
                    <div className="block_more" onClick={handleClickMore}>
                      <div className="num_show">+ 5</div>
                    </div>
                  )}
                </div>
              ) : (
                !e.isDelete && (
                  <div
                    key={i + 1}
                    className="show_img"
                    style={{ backgroundImage: `url(${e.media_uri})` }}
                  >
                    <div className="close" onClick={() => onClickRemove(i, e)}>
                      <Icons.CloseX />
                    </div>
                    {isShowLoadMore && i === 2 && (
                      <div className="block_more" onClick={handleClickMore}>
                        <div className="num_show">+ {files.length}</div>
                      </div>
                    )}
                  </div>
                )
              )
            )}
        {(files.length < 1 || (files.length <= 1 && haveDelete)) && (
          <div {...getRootProps({ className: 'box_upload' })}>
            <input {...getInputProps()} />
            <div className="show_svg">
              <Icons.AddRound />
            </div>
            <div className="h1">เพิ่มรูปภาพ</div>
            <div className="sub">Vdo File Location</div>
          </div>
        )}
        {isShowLoadMore && (
          <div className="btn_show">
            <Buttons.BtnShadow
              theme_btn_shadow_white
              label="แสดงรูปภาพทั้งหมด"
              svg={<Icons.ArrowDropdown width="9.74" height="16" />}
              fontSize="16px"
              fontFamily="kanit_medium"
              onClick={handleClickMore}
            />
          </div>
        )}
      </div>
      {errors && <div className="errors">{errors}</div>}
    </UploadSetImageStyled>
  );
};

UploadSetImage.propTypes = {};

// const data = [
//   {
//     url: '/assets/images/backgrounds/corona.png',
//   },
//   {
//     url: '/assets/images/backgrounds/corona.png',
//   },
//   {
//     url: '/assets/images/backgrounds/corona.png',
//   },
//   {
//     url: '/assets/images/backgrounds/corona.png',
//   },
// ];

export default UploadSetImage;
