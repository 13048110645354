import styled from 'styled-components';

export const LoginContainerStyled = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .body_layout {
    .logo {
      width: 478px;
      height: auto;
    }
    .title {
      font-size: ${({ theme }) => theme.FONT.SIZE.S40};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
    }
    .sub_title {
      margin-bottom: 50px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
    }
  }
`;
