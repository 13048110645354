import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputSelectCusIconStyled, OptionStyled } from './styled';
import Select, { components } from 'react-select';
import { Icons } from 'components';
// import theme from 'styles/theme.json';

const InputSelectCusIcon = ({
  theme_standard,
  theme_language,
  errors,
  options,
  onChange,
  statusIconOptions,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_language: theme_language,
  });

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: `1px solid #EDEDED`,
      color: state.selectProps.menuColor,
      padding: 0,
      fontSize: '16px',
      zIndex: 22,
    }),

    control: () => ({
      display: 'flex',
      alignItems: 'center',
      padding: '5px 14px',
      width: 'calc(100% - 30px)',
      border: `1px solid #EDEDED`,
      borderRadius: '15px',
      boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16)',
      cursor: 'pointer',
      fontSize: '20px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const CustomOption = ({ innerProps, isDisabled, data }) =>
    !isDisabled ? (
      <OptionStyled {...innerProps}>
        <div className="op_icon_wrap">{statusIconOptions[data.value]}</div>
        <div className="op_label_wrap">{data.label}</div>
      </OptionStyled>
    ) : null;

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <Icons.ArrowDropdown translate="21.81" rotate="90" color="#575D96" />
        ) : (
          <Icons.ArrowDropdown color="#575D96" />
        )}
      </components.DropdownIndicator>
    );
  };

  return (
    <InputSelectCusIconStyled>
      <div className={customClass}>
        <Select
          styles={customStyles}
          defaultValue={options[0]}
          options={options}
          {...props}
          onChange={(e) => onChange({ value: e.value, label: e.label })}
          components={{
            Control: ({ children, selectProps, ...rest }) => {
              return (
                <components.Control {...rest}>
                  {statusIconOptions[selectProps.value.value]}
                  {children}
                </components.Control>
              );
            },
            Option: CustomOption,
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      {errors && <div className="error">{errors}</div>}
    </InputSelectCusIconStyled>
  );
};

InputSelectCusIcon.propTypes = {};

export default InputSelectCusIcon;
