import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { TopBottomStyled } from './styled';

const TopBottom = ({
  theme_standard_top_bottom,
  theme_mini_top_bottom,
  theme_left_align,
  label,
  labelBottom,
  fontSize,
  fontFamilyTop,
  fontFamilyBottom,
  marginTopBottomLabel,
  fontColor,
  colorBottom,
  fontSizeLabelButtom,
  lineHeight,
  warpText,
}) => {
  const customClass = cx({
    theme_standard_top_bottom: theme_standard_top_bottom,
    theme_mini_top_bottom: theme_mini_top_bottom,
    theme_left_align: theme_left_align,
  });
  return (
    <TopBottomStyled
      fontSize={fontSize}
      fontFamilyTop={fontFamilyTop}
      fontFamilyBottom={fontFamilyBottom}
      marginTopBottomLabel={marginTopBottomLabel}
      fontColor={fontColor}
      colorBottom={colorBottom}
      fontSizeLabelButtom={fontSizeLabelButtom}
      lineHeight={lineHeight}
      warpText={warpText}
    >
      <div className={customClass} >
        <div className='top_label'>
          {label}
        </div>
        {
          labelBottom &&
          <div className='bottom_label'>
            {labelBottom}
          </div>
        }
      </div>
    </TopBottomStyled>
  );
};

TopBottom.propTypes = {};

export default TopBottom;
