import styled from 'styled-components';

export const TopBottomStyled = styled.div`
  .theme_standard_top_bottom { 
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 'unset')};
    .top_label {
      font-size: ${(props) =>
    props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${(props) =>
    props.fontFamilyTop
      ? props.fontFamilyTop
      : ({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      color: ${(props) =>
    props.fontColor
      ? props.fontColor
      : ({ theme }) => theme.COLORS.BLACK_1};
    }
    .bottom_label {
      white-space: ${({ warpText }) => (warpText ? 'pre-wrap' : 'nowrap')};
      font-family: ${(props) =>
    props.fontFamilyBottom
      ? props.fontFamilyBottom
      : ({ theme }) => theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
    props.fontSizeLabelButtom
      ? props.fontSizeLabelButtom
      : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
    props.colorBottom
      ? props.colorBottom
      : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
    props.marginTopBottomLabel ? props.marginTopBottomLabel : '3px'};
    }
  }

  .theme_mini_top_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.4;
    .top_label {
      font-size: ${(props) =>
    props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${(props) =>
    props.fontFamilyTop
      ? props.fontFamilyTop
      : ({ theme }) => theme.FONT.STYLE.BOLD};
      color: ${(props) =>
    props.fontColor
      ? props.fontColor
      : ({ theme }) => theme.COLORS.BLACK_1};
    }
    .bottom_label {
      white-space: ${({ warpText }) => (warpText ? 'pre-wrap' : 'nowrap')};
      font-family: ${(props) =>
    props.fontFamilyBottom
      ? props.fontFamilyBottom
      : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
    props.fontSizeLabelButtom
      ? props.fontSizeLabelButtom
      : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
    props.colorBottom
      ? props.colorBottom
      : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
    props.marginTopBottomLabel ? props.marginTopBottomLabel : '0px'};
    }
  }
  .theme_left_align {
    display: flex;
    flex-direction: column;
    line-height: 1.4;
    .top_label {
      font-size: ${(props) =>
    props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${(props) =>
    props.fontFamilyTop
      ? props.fontFamilyTop
      : ({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      color: ${(props) =>
    props.fontColor
      ? props.fontColor
      : ({ theme }) => theme.COLORS.BLACK_1};
    }
    .bottom_label {
      flex: 1;
      white-space: ${({ warpText }) => (warpText ? 'pre-wrap' : 'nowrap')};
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: ${(props) =>
    props.fontFamilyBottom
      ? props.fontFamilyBottom
      : ({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      font-size: ${(props) =>
    props.fontSizeLabelButtom
      ? props.fontSizeLabelButtom
      : ({ theme }) => theme.FONT.SIZE.S16};
      color: ${(props) =>
    props.colorBottom
      ? props.colorBottom
      : ({ theme }) => theme.COLORS.GRAY_4};
      margin-top: ${(props) =>
    props.marginTopBottomLabel ? props.marginTopBottomLabel : '0px'};
    }
  }
`;
