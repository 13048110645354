import styled from 'styled-components'

export const PlayAudioStyled = styled.div`
  .mp3_wrap {
    // position: relative;
    padding: 13px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    color: ${({ theme }) => theme.COLORS.GRAY_1};
    font-family: noto_sans_thai_regular, noto_sans_regular;
    width: 100%;
    border: none;
    border-radius: 10px;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: ${({ theme }) => theme.COLORS.GRAY_3};
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: ${({ theme }) => theme.COLORS.GRAY_3};
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: ${({ theme }) => theme.COLORS.GRAY_3};
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: ${({ theme }) => theme.COLORS.GRAY_3};
    }
    .show_svg {
      display: flex;
      margin-right: 15px;
    }
    .mp3_label {

    }
  }
`
