import React, { useState, useEffect } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputTextRichStyled } from './styled';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const InputTextRich = ({
  theme_standard,
  labelTitle,
  labelSub,
  maxLength,
  onChange,
  value,
  height,
  toolbarHidden,
  errors,
  // svg,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [count, setCount] = useState(0);
  const [isInitial, setIsInitial] = useState(false);
  const [_value, _setValue] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (value && !isInitial) {
      const blocksFromHtml = htmlToDraft(value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      _setValue(editorState);
      setCount(editorState.getCurrentContent().getPlainText().length);
      setIsInitial(true);
    }
  }, [value, isInitial]);

  const handleChange = (e) => {
    setIsInitial(true);
    _setValue(e);
    setCount(e.getCurrentContent().getPlainText().length);
    onChange && onChange(draftToHtml(convertToRaw(e.getCurrentContent())));
  };

  return (
    <InputTextRichStyled height={height}>
      <div className={customClass}>
        <div className="title_layout">
          {labelTitle && (
            <div className="show_title">
              <div className="title_display">{labelTitle}</div>
              <div className="sub_display">{labelSub}</div>
            </div>
          )}
          {maxLength && (
            <div className="num_show">
              {`${count}/${maxLength ? maxLength : 100}`}
            </div>
          )}
        </div>
        <div className="editor_wrap">
          <Editor
            editorClassName="text_area"
            editorState={_value}
            onEditorStateChange={handleChange}
            toolbarHidden={toolbarHidden}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'embedded',
                'emoji',
                'image',
                'remove',
                'history',
              ],
            }}
            handleBeforeInput={(val) => {
              const textLength =
                _value && _value.getCurrentContent().getPlainText().length;
              if (val && textLength >= maxLength) {
                return 'handled';
              }
              return 'not-handled';
            }}
            handlePastedText={(val) => {
              const textLength = _value
                .getCurrentContent()
                .getPlainText().length;
              return val.length + textLength >= maxLength;
            }}
          ></Editor>
        </div>
      </div>
      {errors && <div className="error">{`* ${errors}`}</div>}
    </InputTextRichStyled>
  );
};

InputTextRich.propTypes = {};

export default InputTextRich;
