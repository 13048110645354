import React, { useEffect } from 'react';
import { AddVideoFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import { Inputs, Icons, UploadSetImage, UploadSetImage2, Buttons, Displays } from 'components';
import { userService } from 'apiService';
import { Icons, Buttons, Inputs } from 'components';
import { toast } from 'react-toastify';

const AddVideoForm = ({ initialValues, onSubmit, handleBack, isDisabled }) => {
  const schema = yup.object().shape({
    topic: yup.string().nullable().required('Topic is required'),
    body: yup.string().nullable().required('Content is required'),
    categories: yup
      .array()
      .min(1)
      .nullable()
      .required('Categories is required'),
    tags: yup.array().min(1).nullable().required('Tag is required'),
    cover: yup.array().min(1).nullable().required('Cover is required'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        is_draft: initialValues.is_draft ? statusOptions[0] : statusOptions[1],
        cover: initialValues.cover_uri
          ? [{ media_uri: initialValues.cover_uri }]
          : [],
        video480: initialValues.video480_uri
          ? [{ media_uri: initialValues.video480_uri }]
          : [],
        video720: initialValues.video720_uri
          ? [{ media_uri: initialValues.video720_uri }]
          : [],
        video1080: initialValues.video1080_uri
          ? [{ media_uri: initialValues.video1080_uri }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const statusOptions = [
    {
      label: 'ไม่เผยแพร่',
      value: 'true',
      // icon: <Icons.Lock />,
    },
    {
      label: 'สาธารณะ',
      value: 'false',
      // icon: <Icons.Gobal />,
    },
  ];

  const statusIconOptions = {
    true: <Icons.Lock />,
    false: <Icons.Gobal />,
  };

  const _onSubmit = (values) => {
    let haveDeleteCover = values.cover.some((e) => e.isDelete)
    let haveNewCover = values.cover.some((e) => e.isNewUpload)
    let haveDeleteVid480 = values.video480.some((e) => e.isDelete)
    let haveNewVid480 = values.video480.some((e) => e.isNewUpload)
    let haveDeleteVid720 = values.video720.some((e) => e.isDelete)
    let haveNewVid720 = values.video720.some((e) => e.isNewUpload)
    let haveDeleteVid1080 = values.video1080.some((e) => e.isDelete)
    let haveNewVid1080 = values.video1080.some((e) => e.isNewUpload)

    let checkPassCover = false
    let checkPassAllVid = false
    let checkPassVid480 = false
    let checkPassVid720 = false
    let checkPassVid1080 = false

    if (initialValues) {
      // เช็ครูปต้องมี
      if (haveDeleteCover) {
        if (haveNewCover) {
          checkPassCover = true
        } else {
          checkPassCover = false
        }
      } else {
        checkPassCover = true
      }

      // เช็ค vid 480
      if (haveDeleteVid480) {
        if (haveNewVid480) {
          checkPassVid480 = true
        } else {
          checkPassVid480 = false
        }
      } else {
        checkPassVid480 = true
      }

      // เช็ค vid 720
      if (haveDeleteVid720) {
        if (haveNewVid720) {
          checkPassVid720 = true
        } else {
          checkPassVid720 = false
        }
      } else {
        checkPassVid720 = true
      }

      // เช็ค vid 1080
      if (haveDeleteVid1080) {
        if (haveNewVid1080) {
          checkPassVid1080 = true
        } else {
          checkPassVid1080 = false
        }
      } else {
        checkPassVid1080 = true
      }
      // ถ้าไม่มีการแก้ไขภาพหรือวิดีโอเลย
      if (
        !haveDeleteVid480 &&
        !haveDeleteVid720 &&
        !haveDeleteVid1080 &&
        !haveNewVid480 &&
        !haveNewVid720 &&
        !haveNewVid1080 &&
        checkPassVid480 &&
        checkPassVid720 &&
        checkPassVid1080
      ) {
        checkPassAllVid = true
      } else {
        // ถ้าหากมีการแก้ไข้วิดีโอ อันใดอันหนึ่ง
        if (haveDeleteVid480 || haveDeleteVid720 || haveDeleteVid1080) {
          let lengthVide480 = values.video480.some((e) => !e.isDelete && !e.isNewUpload)
          let lengthVide720 = values.video720.some((e) => !e.isDelete && !e.isNewUpload)
          let lengthVide1080 = values.video1080.some((e) => !e.isDelete && !e.isNewUpload)

          // เช็คว่ามี Video อย่างน้อย 1 อันหรือไม่
          if (lengthVide480 || lengthVide720 || lengthVide1080) {
            checkPassAllVid = true
          } else {
            // เช็คว่ามีการอัพโหลดวิดีโออย่างน้อย 1 อันหรือไม่
            if (haveNewVid480 || haveNewVid720 || haveNewVid1080) {
              checkPassAllVid = true
            } else {
              checkPassAllVid = false
            }
          }
        } else {
          checkPassAllVid = true
        }
      }

      if (!checkPassAllVid) {
        toast.error('กรุณาอัพโหลดวิดีโอ อย่างน้อย 1 วิดีโอ');
      } else if (!checkPassCover) {
        toast.error('กรุณาอัพรูปปก');
      } else {
        onSubmit(values);
      }
    } else {
      if (
        (values.video480.length > 0 &&
          values.video480.some((e) => e.isNewUpload)) ||
        (values.video720.length > 0 &&
          values.video720.some((e) => e.isNewUpload)) ||
        (values.video1080.length > 0 &&
          values.video1080.some((e) => e.isNewUpload))
      ) {
        onSubmit(values);
      } else {
        toast.error('กรุณาอัพโหลดวิดีโอ อย่างน้อย 1 วิดีโอ');
      }
    }
  };

  return (
    <AddVideoFormStyled>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="clinic_show">
          <div className="back_link">
            <Buttons.BtnLink
              theme_standard_btn_link
              svg={<Icons.ArrowLeft />}
              label="ย้อนกลับ"
              onClick={handleBack}
            />
          </div>
        </div>
        <div className="top_form_show">
          <div className="left_box">
            <div className="svg_edit">
              <Icons.Content />
            </div>
            <div>{initialValues ? 'แก้ไขVideo' : 'HerWill Video Upload'}</div>
          </div>
          <div className="btn_add">
            <Buttons.BtnPrefixIcon
              theme_red
              label={initialValues ? 'บันทึกการแก้ไข' : 'บันทึก'}
              disabled={isDisabled}
              type="submit"
              fontSize="16px"
              fontFamily="kanit_medium"
            />
          </div>
        </div>
        <div className="body_form">
          <div className="input_top">
            <div className="layer_two">
              <div className="left_layer_two">
                <div className="mid_form">
                  <div className="line_mid">
                    <div className="mid_left">
                      <div className="group_mid_form">
                        <div className="line_label">
                          <div className="label_1">หมวด</div>
                          <div className="label_2">
                            Category (เลือกได้มากกว่า 1 หมวดหมู่)
                          </div>
                        </div>
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.InputSelectLoadmore
                              theme_input
                              {...field}
                              endPoint={userService.GET_CONTENT_CATEGORY}
                              placeholder="หมวด"
                              errors={errors.categories?.message}
                            />
                          )}
                          name="categories"
                          defaultValue=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mid_form">
                  <div className="line_mid">
                    <div className="mid_left">
                      <div className="group_mid_form">
                        <div className="line_label mt12">
                          <div className="label_1">วิดีโอ</div>
                          <div className="label_2">Tag</div>
                        </div>
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.InputTag
                              theme_white_shadow
                              {...field}
                              theme_login
                              // placeholder={'User Id'}
                              errors={errors.tags?.message}
                            />
                          )}
                          name="tags"
                          defaultValue=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mid_form">
                  <div className="line_mid">
                    <div className="mid_left">
                      <div className="group_mid_form">
                        <div className="line_label mt12">
                          <div className="label_1">สถานะ</div>
                          <div className="label_2">Status</div>
                        </div>
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.InputSelectCusIcon
                              theme_standard
                              {...field}
                              iconSvg={<Icons.Lock />}
                              iconSvgClose={<Icons.ArrowDropdown />}
                              iconSvgOpen={
                                <Icons.ArrowDropdown
                                  translate="21.81"
                                  rotate="90"
                                />
                              }
                              options={statusOptions}
                              statusIconOptions={statusIconOptions}
                            />
                          )}
                          name="is_draft"
                          defaultValue={{
                            label: 'ไม่เผยแพร่',
                            value: 'true',
                            // icon: <Icons.Lock />,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="zone_upload">
                  <div className="header_zone">
                    <div className="label_1">ภาพปกบทความ</div>
                    <div className="label_2">Cover photo</div>
                  </div>
                  <div className="upload_layer">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.UploadSetImage2
                          theme_upload_set_image
                          acceptType="cover"
                          maxMBFileSizeWarning="1"
                          {...field}
                          svg={<Icons.Delete3 />}
                          theme_left_right={false}
                          width="100%"
                          errors={errors.cover?.message}
                        />
                      )}
                      name="cover"
                      defaultValue={[]}
                    />
                  </div>
                </div>
              </div>
              <div className="right_layer_two">
                <div>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextArea2
                        theme_standard_text_area
                        labelTitle="หัวข้อ"
                        labelSub="Topic"
                        maxLength={120}
                        height="58px"
                        placeholder="หัวข้อ"
                        {...field}
                        // placeholder={t('test')}
                        errors={errors.topic?.message}
                      />
                    )}
                    name="topic"
                    defaultValue=""
                  />
                </div>
                <div className="right_text_rich">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextRich
                        labelTitle="เนื้อหา"
                        labelSub="Content"
                        maxLength={1500}
                        placeholder="เนื้อหา"
                        svg={<Icons.Pencil2 />}
                        height="350px"
                        {...field}
                        // placeholder={t('test')}
                        errors={errors.body?.message}
                      />
                    )}
                    name="body"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="zone_vid_upload">
            <div className="group_upload">
              <div className="label_zone">
                <div className="zone_1">ช่องอัพโหลดวิดีโอ</div>
                <div className="zone_2">(ขนาด : 480p)</div>
              </div>
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputBrowse
                    theme_standard_browse
                    placeholder="Vdo File Location"
                    acceptType="video"
                    maxMBFileSizeWarning="20"
                    // value={value}
                    // onChange={onChange}
                    svg={<Icons.Delete />}
                    theme_left_right={true}
                    {...field}
                    errors={errors.video480?.message}
                  />
                )}
                name="video480"
                defaultValue={[]}
              />
            </div>
            <div className="group_upload">
              <div className="label_zone">
                <div className="zone_1">ช่องอัพโหลดวิดีโอ</div>
                <div className="zone_2">(ขนาด : 720p)</div>
              </div>
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputBrowse
                    theme_standard_browse
                    placeholder="Vdo File Location"
                    acceptType="video"
                    maxMBFileSizeWarning="20"
                    // value={value}
                    // onChange={onChange}
                    svg={<Icons.Delete />}
                    theme_left_right={true}
                    {...field}
                  />
                )}
                name="video720"
                defaultValue={[]}
              />
            </div>
            <div className="group_upload">
              <div className="label_zone">
                <div className="zone_1">ช่องอัพโหลดวิดีโอ</div>
                <div className="zone_2">(ขนาด : 1080p)</div>
              </div>
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputBrowse
                    theme_standard_browse
                    placeholder="Vdo File Location"
                    acceptType="video"
                    maxMBFileSizeWarning="20"
                    // value={value}
                    // onChange={onChange}
                    svg={<Icons.Delete />}
                    theme_left_right={true}
                    {...field}
                  />
                )}
                name="video1080"
                defaultValue={[]}
              />
            </div>
          </div>
        </div>
      </form>
    </AddVideoFormStyled>
  );
};

AddVideoForm.propTypes = {};

export default AddVideoForm;
