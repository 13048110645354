import React, { useState } from 'react';
import cx from 'classnames';
import { InputSelectStyled } from './styled';
import Select, { components } from 'react-select';
import { Icons } from 'components';

const InputSelect = ({
  theme_standard,
  placeholder,
  label,
  errors,
  onChange,
  options,
  disabled,
  value
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [selectedOption,] = useState(null);

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Icons.ArrowDropdown />
      </components.DropdownIndicator>
    );
  };

  // const Option = props => {
  //   return (
  //     <components.Option {...props}>
  //       <input
  //         type="checkbox"
  //         checked={props.isSelected}
  //         onChange={() => null}
  //       />{" "}
  //       <label>{props.label}</label>
  //     </components.Option>
  //   );
  // };

  const styles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      padding: '10px 6.7px',
      boxShadow: '0px 3px 6px 0px rgb(0 0 0 / 16%)',
      borderRadius: '12px',
      color: state.isDisabled && '#3B424C',
      background: state.isDisabled && 'white',
      // '&:hover': {
      //    border: state.isFocused ? 0 : 0
      // }
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        'width': '6px',
        'height': '6px'
      },
      "::-webkit-scrollbar-button": {
        'width': '0px',
        'height': '0px',
      },
      "::-webkit-scrollbar-thumb": {
        'background': '#404458',
        'border': '0px none #ffffff',
        'border-radius': '50px',
      },
      " ::-webkit-scrollbar-thumb:hover": {
        'background': '#404458',
      },
      "::-webkit-scrollbar-thumb:active": {
        'background': '#404458',
      },
      "::-webkit-scrollbar-track": {
        'background': '#ffffff',
        'border': '0px none #ffffff',
        'border-radius': '42px',
      },
      " ::-webkit-scrollbar-track:hover": {
        'background': ' #ffffff',
      },
      "::-webkit-scrollbar-track:active": {
        'background': '#ffffff'
      },
      "::-webkit-scrollbar-corner": {
        'background': 'transparent'
      },
    })
  }

  return (
    <InputSelectStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <Select
          defaultValue={selectedOption}
          styles={styles}
          className='select_container'
          onChange={onChange}
          value={value}
          isMulti
          options={options && options}
          placeholder={placeholder}
          isDisabled={disabled}
          components={{ DropdownIndicator }}
        // components={{ DropdownIndicator, Option }}
        />
      </div>
      {errors && <div className="error">{errors}</div>}
    </InputSelectStyled>
  );
};

InputSelect.propTypes = {};

export default InputSelect;
