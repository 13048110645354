export default function renderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="19"
      viewBox="0 0 11 19"
    >
      <g
        id="Group_86578"
        data-name="Group 86578"
        transform="translate(-408 -276.799)"
      >
        <g
          id="Group_86570"
          data-name="Group 86570"
          transform="translate(408 277)"
        >
          <rect
            id="Rectangle_26430"
            data-name="Rectangle 26430"
            width="5"
            height="5"
            transform="translate(0 -0.201)"
            fill="rgba(112,112,112,0.4)"
          />
          <rect
            id="Rectangle_26431"
            data-name="Rectangle 26431"
            width="5"
            height="5"
            transform="translate(6 -0.201)"
            fill="rgba(112,112,112,0.4)"
          />
        </g>
        <g
          id="Group_86571"
          data-name="Group 86571"
          transform="translate(408 283.768)"
        >
          <rect
            id="Rectangle_26430-2"
            data-name="Rectangle 26430"
            width="5"
            height="5"
            transform="translate(0 0.031)"
            fill="rgba(112,112,112,0.4)"
          />
          <rect
            id="Rectangle_26431-2"
            data-name="Rectangle 26431"
            width="5"
            height="5"
            transform="translate(6 0.031)"
            fill="rgba(112,112,112,0.4)"
          />
        </g>
        <g
          id="Group_86572"
          data-name="Group 86572"
          transform="translate(408 290.535)"
        >
          <rect
            id="Rectangle_26430-3"
            data-name="Rectangle 26430"
            width="5"
            height="5"
            transform="translate(0 0.264)"
            fill="rgba(112,112,112,0.4)"
          />
          <rect
            id="Rectangle_26431-3"
            data-name="Rectangle 26431"
            width="5"
            height="5"
            transform="translate(6 0.264)"
            fill="rgba(112,112,112,0.4)"
          />
        </g>
      </g>
    </svg>
  );
}
