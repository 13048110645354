import styled from 'styled-components';

export const BoxStyled = styled.div`
  height: ${(props) => (props.height ? props.height : 'auto')};
  .theme_standard_box {
    position: relative;
    height: ${(props) => (props.height ? props.height : 'auto')};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '16px')};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : '16px'};
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : '16px'};
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : '16px'};
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : '10px'};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
  }
  .theme_standard_box_white {
    position: relative;
    height: ${(props) => (props.height ? props.height : 'auto')};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '24px')};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : '24px'};
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : '24px'};
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : '24px'};
    border-top-left-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : '18px'};
    border-top-right-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : '18px'};
    border-top: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
    border-left: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
    border-right: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
  }
  .theme_gray_box {
    position: relative;
    height: ${(props) => (props.height ? props.height : 'auto')};
    background: ${({ theme }) => theme.COLORS.GRAY_8};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '20px')};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : '20px'};
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : '20px'};
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : '20px'};
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : '8px'};
  }
`;
