import React from 'react';
import { VideoDetailContainerStyled } from './styled';
import { Displays, Icons, Buttons } from 'components';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { userService } from 'apiService';
import { connect } from 'react-redux';
import { withRouter } from 'navigations/withRouter';
// import { toast } from 'react-toastify';

class VideoDetailContainer extends React.Component {
  state = {
    isLoading: true,
    category: '',
    tag: '',
    // renderModal: null,
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await userService.GET_CONTENT_DETAIL(this.props.router.params.id);
   
    if (res && res.success) {
      let catText = res.data.categories.map((e) => e.name_th).join(',')
      let tagText = ''
      res.data.tags.forEach((e) => {
        tagText = tagText + `#${e.tag} `
      })
      this.setState({
        data: res.data,
        category: catText,
        tag: tagText,
        isLoading: false,
      });
    }
  };


  handleBackClick = () => {
    this.props.router.navigate(`${ROUTE_PATH.VIDEO}`);
  };

  handleClickEditContent = () => {
    this.props.router.navigate(`${ROUTE_PATH.VIDEO_DETAIL}/${this.props.router.params.id}`);
  };

  render() {
    const { data, isLoading, category, tag } = this.state;

    return (
      <VideoDetailContainerStyled
        src={data && data.cover_thumbnail_uri}
      >
        {isLoading ? (
          <div className="show_loading">
            <Displays.Loading />
          </div>
        ) : (
          <div className="box_layout">
            <Buttons.BtnLink
              theme_standard_btn_link
              svg={<Icons.ArrowLeft />}
              label="ย้อนกลับ"
              onClick={this.handleBackClick}
            />
            <div className="layer_one">
              <div className="box_title">
                <div className="svg_edit">
                  <Icons.Content />
                </div>
                <div>HerWill Video Category</div>
              </div>
              <div className='btn_edit'>
                <Buttons.BtnPrefixIcon
                  theme_back_gray
                  fontSize="18px"
                  fontFamily="kanit_bold"
                  label="แก้ไข"
                  iconBack={<Icons.Edit color='#ffffff' />}
                  onClick={this.handleClickEditContent}
                />
              </div>
            </div>
            <div className='box_detail'>
              <div className='box_shadow'>
                {
                  data && data.cover_thumbnail_uri
                    ?
                    <div className='img_show' />
                    :
                    <div className='blank_img' />
                }
                <div className='group_label'>
                  <div className='big_text'>
                    {data?.topic}
                  </div>
                  <div className='mid_text'>
                    {`หมวดหมู่วิดีโอ : ${category}`}
                  </div>
                  <div className='small_text'>
                    {`วิดีโอ Tag : ${tag}`}
                  </div>
                </div>
              </div>
              <div className='box_pink'>
                <div dangerouslySetInnerHTML={{ __html: data?.body }}></div>
              </div>
            </div>
          </div>
        )}
      </VideoDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  // authenRedux: state.authenRedux,
  // contentCategory: state.contentCategory,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VideoDetailContainer));