import styled from 'styled-components';

export const TextFieldStyled = styled.div`
  .label {
  }
  input {
    border: 1px solid ${({ theme }) => theme.COLORS.PINK_1}26;
    border-radius: 8px;
    padding: 15px 32px;
    width: calc(100% - 66px);
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLACK_4};
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_white_shadow {
    input {
      border: 1px solid #EDEDED;
      padding: 12px;
      border-radius: 12px;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      width: 100%;
      box-sizing: border-box;
    }
  }

  .theme_normal {
    input {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      padding: 14px 20px;
    }
  }
  .theme_standard {
  }
`;
