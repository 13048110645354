import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { CategoryItemStyled } from './styled';
import { Icons, Inputs } from 'components';

const CategoryItem = ({
  theme_standard,
  isSelected,
  isDrag,
  no,
  cateName,
  onSelected,
  disabled,
  checked,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <CategoryItemStyled disabled={disabled}>
      <div className={customClass}>
        <div className="cate_item_container">
          {no && <div className="c_no">{no}</div>}
          <div className="c_panel_row">
            {isDrag && (
              <div className="c_drag_drop_wrap">
                <Icons.DragDrop />
              </div>
            )}
            {isSelected && (
              <Inputs.InputCheckBox
                theme_standard_input_check_box_round
                name={no}
                // value={'isSelectedAll'}
                checked={checked}
                onChange={onSelected}
                disabled={disabled}
              />
            )}
            <div className="c_cate_name">{cateName}</div>
          </div>
        </div>
      </div>
    </CategoryItemStyled>
  );
};

CategoryItem.propTypes = {};

export default CategoryItem;
