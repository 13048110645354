import React from 'react';

export default function renderIcon({
  width = "13.067",
  height = "12.736",
  color = '#6f8595',
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13.067 12.736">
      <path id="Icon_awesome-arrow-left" data-name="Icon awesome-arrow-left" d="M7.507,14.53l-.647.647a.7.7,0,0,1-.989,0L.2,9.511a.7.7,0,0,1,0-.989L5.871,2.853a.7.7,0,0,1,.989,0l.647.647a.7.7,0,0,1-.012,1L3.982,7.849h8.381a.7.7,0,0,1,.7.7v.933a.7.7,0,0,1-.7.7H3.982L7.5,13.529A.7.7,0,0,1,7.507,14.53Z" transform="translate(0.004 -2.647)" fill={color} />
    </svg>
  );
}
