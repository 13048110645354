import styled from 'styled-components';

export const CategoryManageContainerStyled = styled.div`
  position: relative;
  border-radius: 1px solid ${({ theme }) => theme.COLORS.PINK_1};
  border-radius: 14px;
  padding: 56px 16px 16px 16px;
  width: 100%;
  max-width: 805px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .icon_close {
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
  }
  .cm_title {
    margin-bottom: 40px;
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S22};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_SEMIBOLD};
    color: ${({ theme }) => theme.COLORS.BLACK_6};
  }
  .sec_body {
    margin: auto;
    width: 100%;
    max-width: 674px;
    .cm_action_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 4px;
      .ca_left_col {
        flex: 1;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_6};
      }
      .ca_right_col {
        flex-shrink: 0;
        width: 288px;
        display: flex;
        column-gap: 16px;
        .ca_btn_wrap {
          flex: 1;
        }
      }
    }
    .cm_list_block {
      .loading {
        text-align: center;
      }
      .cm_item_wrap {
        margin-bottom: 8px;
      }
    }
  }
`;
