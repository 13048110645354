import { default as BgStandard } from './BgStandard';
import { default as BtnPrefixIcon } from './BtnPrefixIcon';
import { default as BtnLink } from './BtnLink';
import { default as BtnIcon } from './BtnIcon';
import { default as BtnNormal } from './BtnNormal';

const Export = { 
    BgStandard ,
    BtnPrefixIcon,
    BtnLink,
    BtnIcon,
    BtnNormal,
};

export default Export;
