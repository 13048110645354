import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    overflow: hidden;
    body {
      margin: 0;
      width: 100%;
      height: 100%;
      position: fixed;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;  
      position: relative;
      font-family: kanit_regular;
      font-size: 16px;
      color: #1A1A1A;
    }
     button {
      border: none; 
      &:focus {
        outline: none;
      }
    }
    input {
      &:focus {
        outline: none;
      }
    }
  }
`;
