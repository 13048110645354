import styled from 'styled-components';

export const BtnIconStyled = styled.div`
  .theme_standard_btn_icon {
    width: 48px;
    height: 48px;
    box-shadow: 0 2px 9px -5px rgb(0 0 0 / 75%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    cursor: pointer;
  }
`;
