import React from 'react';
import { ContentDetailContainerStyled } from './styled';
import { Displays, Icons, Buttons } from 'components';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { userService } from 'apiService';
import { connect } from 'react-redux';
import { withRouter } from 'navigations/withRouter';
// import { toast } from 'react-toastify';

class ContentDetailContainer extends React.Component {
  state = {
    isLoading: false,
    category: '',
    tag: '',
    // renderModal: null,
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await userService.GET_CONTENT_DETAIL(this.props.router.params.id);

    if (res && res.success) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };


  handleBackClick = () => {
    this.props.router.navigate(`${ROUTE_PATH.CONTENT}`);
  };

  handleClickEditContent = () => {
    this.props.router.navigate(`${ROUTE_PATH.CONTENT_DETAIL}/${this.props.router.params.id}`);
  };

  renderIcon = (e) => {
    if (!e) {
      return (
        <>
          <div className='svg_status'>
            <Icons.StatusPublic width='16px' height='16px' />
          </div>
          <div>
            สาธารณะ
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='svg_status'>
            <Icons.StatusPrivate width='16px' height='16px' />
          </div>
          <div>
            ไม่เผยแพร่
          </div>
        </>
      )
    }
  }

  render() {
    const { data, isLoading, } = this.state;

    return (
      <ContentDetailContainerStyled
        src={data && data.cover_thumbnail_uri}
      >
        {isLoading ? (
          <div className="show_loading">
            <Displays.Loading />
          </div>
        ) : (
          <div className="box_layout">
            <Buttons.BtnLink
              theme_standard_btn_link
              svg={<Icons.ArrowLeft />}
              label="ย้อนกลับ"
              onClick={this.handleBackClick}
            />
            <div className="layer_one">
              <div className="box_title">
                <div className="svg_edit">
                  <Icons.Content />
                </div>
                <div>HerWill Content</div>
              </div>
              <div className='btn_edit'>
                <Buttons.BtnPrefixIcon
                  theme_back_gray
                  fontSize="18px"
                  fontFamily="kanit_bold"
                  label="แก้ไข"
                  iconBack={<Icons.Edit color='#ffffff' />}
                  onClick={this.handleClickEditContent}
                />
              </div>
            </div>
            <div className='box_detail'>
              <div className='box_shadow'>
                {
                  data && data.cover_thumbnail_uri
                    ?
                    <div className='img_show' />
                    :
                    <div className='blank_img' />
                }
                <div className='group_label'>
                  <div className='big_text'>
                    <div>
                      {data?.topic}
                    </div>
                    <div className='status_show'>
                      {data && this.renderIcon(data.is_draft)}
                    </div>
                  </div>
                  <div className='mid_text'>
                    <div dangerouslySetInnerHTML={{ __html: data?.body }}></div>
                  </div>
                  {
                    data && data.link_uri &&
                    <div className='small_text'>
                      {data?.link_uri}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </ContentDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  // authenRedux: state.authenRedux,
  // contentCategory: state.contentCategory,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContentDetailContainer));