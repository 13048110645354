import styled from 'styled-components';

export const InputBrowseStyled = styled.div`
  .errors {
    margin-top: 4px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard_browse {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    .file_location {
      width: 77%;
      .location {
        margin-bottom: 20px;
        border: 2px solid ${({ theme }) => theme.COLORS.PINK_1}26;
        border-radius: 8px;
        padding: 11px 32px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_4};
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
      }
    }
    .btn_browse {
      width: 20%;
    }
  }
  .body_show_video {
    margin-top: 20px;
  }
`;
