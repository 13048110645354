import React from 'react';
import { LoginContainerStyled } from './styled';
import { connect } from 'react-redux';
import { withRouter } from 'navigations/withRouter';
import { setReduxAuthen } from 'store/actions';
import { userService } from 'apiService';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Forms } from 'components';
import { toast } from 'react-toastify';

class LoginContainer extends React.Component {
  state = {};

  fetchMyProfile = () => {
    return new Promise(async (resolve, reject) => {
      let res = await userService.GET_PROFILE_ADMIN_ME();
      if (res) {
        resolve(res);
      }
    });
  };

  handleSubmit = async (values) => {
    let params = {
      ...values,
    };
    let res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      localStorage.setItem('token', res.token);
      let resProfile = await this.fetchMyProfile();
      if (resProfile) {
        this.props.setReduxAuthen(resProfile);
        this.handleLogged();
        toast.success('Login success');
      }
    } else {
      toast.error('Login failed');
    }
  };

  handleLogged = () => {
    this.props.router.navigate(ROUTE_PATH.VIDEO, -1);
  };

  render() {
    return (
      <LoginContainerStyled>
        <div className="body_layout">
          <img
            className="logo"
            alt="herwill logo"
            src="/assets/images/icons/herwill_logo.png"
          />
          <Forms.LoginForm onSubmit={this.handleSubmit} />
        </div>
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginContainer));
