import styled from 'styled-components';

export const BtnPrefixIconStyled = styled.div`
  .btn {
    border-radius: 8px;
    width: 100%;
    padding: 10px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    background: ${({ theme }) => theme.COLORS.PURPLE_3};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme, fontSize }) =>
      fontSize ? fontSize : theme.FONT.SIZE.S12};
    font-family: ${({ theme, fontFamily }) =>
      fontFamily ? fontFamily : theme.FONT.STYLE.MEDIUM};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
    box-shadow: ${({ shadow }) =>
      shadow ? '  0 2px 12px -5px rgba(0, 0, 0, 0.3)' : 'none'};
  }
  .label_bottom {
    font-family: ${(props) =>
      props.fontFamilyBottom ? props.fontFamilyBottom : 'kanit_regular'};
    font-size: ${(props) =>
      props.fontSizeLabelButtom
        ? props.fontSizeLabelButtom
        : ({ theme }) => theme.FONT.SIZE.S12};
    color: ${(props) =>
      props.colorBottom
        ? props.colorBottom
        : ({ theme }) => theme.COLORS.GRAY_1};
    margin-top: ${(props) =>
      props.marginTopBottomLabel ? props.marginTopBottomLabel : '1px'};
    text-align: center;
  }

  .theme_min_blue {
    .btn {
      border-radius: 4px;
      padding: 4px 15px;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.BLUE_1};
    }
  }

  .theme_opacity {
    .btn {
      border: 1px solid
        ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GRAY_2 : theme.COLORS.PINK_1};
      color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_2 : theme.COLORS.PINK_1};
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.WHITE_1 : 'none'};
    }
  }

  .theme_red {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    .btn {
      width: 100%;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.WHITE_1 : theme.COLORS.PINK_1};
    }
  }

  .theme_back_gray {
    .btn {
      width: 100%;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      background: ${({ theme, disabled }) => disabled ? theme.COLORS.WHITE_1 : theme.COLORS.GRAY_3};
    }
  }

  .theme_pink {
    .btn {
      width: 100%;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.RED_4};
    }
  }

  .theme_white {
    .btn {
      width: 100%;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.GRAY_2};
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.WHITE_1};
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_2};
    }
  }

  .theme_red_border {
    .btn {
      border: 1px solid
        ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GRAY_2 : theme.COLORS.PINK_1};
      color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_2 : theme.COLORS.PINK_1};
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.WHITE_1 : 'none'};
  }

  .theme_gray_border {
    .btn {
      width: 100%;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid
        ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GRAY_11 : theme.COLORS.GRAY_3};
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.GRAY_3};
    }
  }

  .theme_white_border_color {
    .btn {
      width: 100%;
      color: ${({ theme }) => theme.COLORS.BLUE_12};
      border: 1px solid
        ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GRAY_11 : theme.COLORS.BLUE_12};
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.WHITE_1};
    }
  }

  .theme_white_shadow {
    .btn {
      width: 100%;
      font-size: ${({ theme, fontSize }) =>
        fontSize ? fontSize : theme.FONT.SIZE.S12};
      font-family: ${({ theme, fontFamily }) =>
        fontFamily ? fontFamily : theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.BLUE_12};
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.WHITE_1};
      box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.3);
    }
  }

  .theme_blue {
    .btn {
      width: 100%;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.BLUE_12};
    }
  }

  .theme_blue_height {
    height: 100%;
    .btn {
      height: 100%;
      width: 100%;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_11 : theme.COLORS.BLUE_12};
    }
  }
`;
