import styled from 'styled-components';

export const NavBodyStyled = styled.div`
  .body_main_layout {
    margin: auto;
    width: calc(100% - 30px);
    max-width: 1216px;
    padding: 104px 15px 15px 15px;
  }
`;
