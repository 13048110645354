import styled from 'styled-components';

export const LoginFormStyled = styled.div`
  .input_wrap {
    margin-bottom: 38px;
  }
  .row {
    display: flex;
    &.between {
      justify-content: space-between;
    }
    &.center {
      justify-content: center;
    }
    &.align_center {
      align-items: center;
    }
    &.mb_40 {
      margin-bottom: 40px;
    }
    &.column_gap_8 {
      column-gap: 8px;
    }
    .btn_wrap {
      min-width: 145px;
    }
    .label_link {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      color: ${({ theme }) => theme.COLORS.GRAY_1}91;
      cursor: pointer;
    }
  }
`;
