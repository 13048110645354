import { default as Logout } from './Logout';
import { default as Close } from './Close';
import { default as Content } from './Content';
import { default as Delete3 } from './Delete3';
import { default as DragDrop } from './DragDrop';
import { default as ArrowLeft } from './ArrowLeft';
import { default as Gobal } from './Gobal';
import { default as Lock } from './Lock';
import { default as StatusPrivate } from './StatusPrivate';
import { default as StatusPublic } from './StatusPublic';
import { default as ArrowDropdown } from './ArrowDropdown';
import { default as AddRound } from './AddRound';
import { default as CloseX } from './CloseX';
import { default as Delete } from './Delete';
import { default as Edit } from './Edit';
import { default as RoundArrowRight } from './RoundArrowRight';
import { default as RoundArrowLeft } from './RoundArrowLeft';

const Export = {
  StatusPrivate,
  RoundArrowRight,
  RoundArrowLeft,
  AddRound,
  ArrowDropdown,
  CloseX,
  Close,
  StatusPublic,
  Logout,
  Content,
  Delete3,
  DragDrop,
  ArrowLeft,
  Gobal,
  Lock,
  Delete,
  Edit,
};

export default Export;
