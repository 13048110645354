import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BtnNormalStyled } from './styled';

const BtnNormal = ({
  theme_big,
  theme_round_purple,
  theme_round_white,
  theme_log_in,
  theme_blue,
  theme_white,
  type = 'button',
  label,
  onClick,
  disabled,
  fontSize,
  fontSizeLabelButtom,
  colorBottom,
  fontFamilyTop,
  fontFamilyBottom,
  backgroundColor,
  fontColor,
}) => {
  const customClass = cx({
    theme_big: theme_big,
    theme_blue: theme_blue,
    theme_round_white: theme_round_white,
    theme_round_purple: theme_round_purple,
    theme_log_in: theme_log_in,
    theme_white: theme_white,
  });
  return (
    <BtnNormalStyled
      disabled={disabled}
      fontSize={fontSize}
      fontSizeLabelButtom={fontSizeLabelButtom}
      colorBottom={colorBottom}
      fontFamilyTop={fontFamilyTop}
      fontFamilyBottom={fontFamilyBottom}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
    >
      <div className={customClass}>
        <button
          className="btn"
          type={type}
          onClick={onClick}
          disabled={disabled}
        >
          {label}
        </button>
      </div>
    </BtnNormalStyled>
  );
};

BtnNormal.propTypes = {};

export default BtnNormal;
