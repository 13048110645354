import styled from 'styled-components';

export const VideoEditContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  .show_loading {
    height: calc(100vh - 189px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content_layout_show {
    width: 1184px;
  }
`;
