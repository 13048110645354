export default function RoundArrowLeft({ width = '47', height = '47', bgColor = '#fff', color = '#394875' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="Group_86582" data-name="Group 86582" transform="translate(-840 -828)">
        <circle id="Ellipse_2188" data-name="Ellipse 2188" cx="24" cy="24" r="24" transform="translate(840 828)" fill="#cf1a71" />
        <g id="Group_2243" data-name="Group 2243" transform="translate(857 841)">
          <path id="Path_5484" data-name="Path 5484" d="M105.865,11.111l8.31-8.31a1.218,1.218,0,0,0,0-1.718l-.728-.728a1.217,1.217,0,0,0-1.718,0l-9.894,9.894a1.226,1.226,0,0,0,0,1.724l9.885,9.884a1.217,1.217,0,0,0,1.719,0l.728-.728a1.217,1.217,0,0,0,0-1.718Z" transform="translate(-101.48 0)" fill="#fff" />
        </g>
      </g>
    </svg>
  );
}
