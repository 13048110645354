import styled from 'styled-components';

export const InputSelectLoadmoreStyled = styled.div`
  .label {
    margin-bottom: 16px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLUE_4};
  }
  .select_container {
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.BLUE_6};
    .css-1s2u09g-control {
      box-shadow: 0 2px 12px -5px rgb(0 0 0 / 30%);
      padding: 6px;
      border-radius: 8px;
      border: none;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.BLUE_6};
    }
    .css-6j8wv5-Input {
    }
  }
  .error {
    margin-top: 3px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
  }

  .theme_standard {
  }
`;
