import React from 'react';
// import { connect } from 'react-redux';
import { CategoryManageContainerStyled } from './styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import { userService } from 'apiService';
import { Icons, Buttons, Cards } from 'components';
import { toast } from 'react-toastify';

class CategoryManageContainer extends React.Component {
  state = {
    data: [],
    dataSelected: [],
    hasMore: true,
    pagination: {
      offset: 0,
      per_page: 10,
    },
  };

  componentDidMount() {
    const { data } = this.state;
    if (!data || data.length === 0) {
      this.fetchData();
    }
  }

  fetchData = async (stringQuery = `?per_page=10&offset=0`) => {
    const { homeCateList } = this.props;
    const {
      isReset,
      pagination: { per_page },
    } = this.state;
    let res = await userService.GET_CATEGORY_LIST(stringQuery);
    if (res && res.success) {
      if ((!res.data || res.data.length === 0) && !isReset) {
        this.setState({
          hasMore: false,
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          data: state.isReset
            ? res.data
            : [
                ...state.data,
                ...res.data.map((e) => ({
                  ...e,
                  isDisabled: homeCateList.find((f) => f.uuid === e.uuid)
                    ? true
                    : false,
                })),
              ],
          hasMore: res.data.length < per_page ? false : true,
          isReset: false,
        }));
      }
    }
  };
 
  fetchMoreData = () => {
    this.setState(
      (state) => ({
        pagination: {
          offset: state.pagination.offset + state.pagination.per_page + 1,
          per_page: state.pagination.per_page,
        },
      }),
      () => {
        this.fetchData(
          `?per_page=${this.state.pagination.per_page}&offset=${this.state.pagination.offset}`
        );
      }
    );
  };

  handleSelected = (e) => {
    const { dataSelected } = this.state;
    if (dataSelected[e.uuid]) {
      let temp = dataSelected;
      delete temp[e.uuid];
      this.setState({
        dataSelected: { ...temp },
      });
    } else {
      if (Object.keys(dataSelected).length === 3) {
        toast.error('เลือกได้ไม่เกิน 3 อัน');
      } else {
        this.setState({
          dataSelected: { ...dataSelected, [e.uuid]: e.uuid },
        });
      }
    }
  };

  handleClickSubmit = async () => {
    const { homeCateList, onSuccess } = this.props;
    const { dataSelected } = this.state;
    let params = {
      categories:
        Object.keys(dataSelected).length === 0
          ? [...homeCateList.map((e) => e.uuid)]
          : [
              ...homeCateList.map((e) => e.uuid),
              ...Object.keys(dataSelected).map((e) => e),
            ],
    };
    params.categories = params.categories.map((e, i) => ({
      uuid: e,
      order: i + 1,
    }));
    let res = await userService.PUT_HOME_CATEGORY_DETAIL(params);
    if (res && res.success) {
      toast.success('ยืนยันสำเร็จ');
      onSuccess && onSuccess();
    }
  };

  render() {
    const { onClose } = this.props;
    const { data, hasMore, dataSelected } = this.state;
    return (
      <CategoryManageContainerStyled>
        <div className="icon_close" onClick={onClose}>
          <Icons.Close />
        </div>
        <div className="cm_title">เลือกหมวดวิดีโอที่โชว์บนหน้า home</div>
        <div className="sec_body">
          <div className="cm_action_row">
            <div className="ca_left_col">หมวดหมู่ที่เลือก</div>
            <div className="ca_right_col">
              <div className="ca_btn_wrap">
                <Buttons.BgStandard
                  label="ยืนยัน"
                  disabled={
                    !dataSelected ||
                    (dataSelected && Object.keys(dataSelected).length === 0)
                  }
                  onClick={this.handleClickSubmit}
                />
              </div>
              <div className="ca_btn_wrap">
                <Buttons.BgStandard
                  theme_cancel
                  label="ยกเลิก"
                  onClick={onClose}
                />
              </div>
            </div>
          </div>
          <div className="cm_list_block">
            <InfiniteScroll
              dataLength={data.length}
              next={this.fetchMoreData}
              hasMore={hasMore}
              loader={<h3 className="loading">Loading...</h3>}
              height={'calc(100vh - 400px)'}
              endMessage={
                <p className="loading">
                  {/* <b>ดูครบทั้งหมดแล้ว</b> */}
                </p>
              }
              style={{ padding: '18px 12px' }}
            >
              {data.map((e, i) => (
                <div key={i} className="cm_item_wrap">
                  <Cards.CategoryItem
                    isSelected
                    no={i + 1}
                    cateName={e.name_th}
                    onSelected={() => this.handleSelected(e)}
                    disabled={e.isDisabled}
                    checked={e.uuid === dataSelected[e.uuid]}
                  />
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </CategoryManageContainerStyled>
    );
  }
}

export default CategoryManageContainer;
