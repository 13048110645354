import { useState, useEffect } from 'react';
import { CategoryDragDropWidgetsStyled } from './styled';
import { Draggable } from 'react-drag-reorder';
import { Cards } from 'components';
import { userService } from 'apiService';
import { toast } from 'react-toastify';

const CategoryDragDropWidgets = ({ data, onSelected }) => {
  const [_data, _setData] = useState();

  useEffect(() => {
    if (data) {
      _setData([...data]);
    }
  }, [data]);

  const _handleSelected = (e) => {
    onSelected && onSelected(e);
  };

  const _handleChangedPosition = async (currentPos, newPos) => {
    let tempData = _data;
    let element = tempData[currentPos];
    tempData.splice(currentPos, 1);
    tempData.splice(newPos, 0, element);
    _setData(tempData);
    let params = {
      categories: tempData.map((e, i) => ({ uuid: e.uuid, order: i + 1 })),
    };
    let res = await userService.PUT_HOME_CATEGORY_DETAIL(params);
    if (res && res.success) {
      toast.success('สลับตำแหน่งสำเร็จ');
    }
  };

  return (
    <CategoryDragDropWidgetsStyled>
      {_data && (
        <Draggable onPosChange={_handleChangedPosition}>
          {_data.map((e, i) => (
            <div key={i} className="cate_item_wrap">
              <Cards.CategoryItem
                isDrag
                isSelected
                no={i + 1}
                cateName={e.name_th}
                onSelected={() => _handleSelected(e)}
              />
            </div>
          ))}
        </Draggable>
      )}
    </CategoryDragDropWidgetsStyled>
  );
};

export default CategoryDragDropWidgets;
