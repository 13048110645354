import { default as LoginForm } from './LoginForm';
import { default as AddVideoForm } from './AddVideoForm';
import { default as CategoryForm } from './CategoryForm';
import { default as AddContentForm } from './AddContentForm';

const Export = {
  LoginForm,
  AddVideoForm,
  CategoryForm,
  AddContentForm,
};

export default Export;
