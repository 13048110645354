import React, { useState, useEffect } from 'react';
import { TableActionStyled } from './styled';
import cx from 'classnames';
import { PaginationCustom, Inputs } from 'components';

export const TableAction = ({
  theme_standard_table_normal,
  theme_standard_table_normal_gray,
  theme_standard_table_action_pink,
  theme_standard_table_group_healthy,
  theme_standard_table_normal_brown,
  theme_standard_table_user,
  columns,
  data,
  pagination,
  showTitle,
  useNo,
  bgColor,
  bgHeader,
  showPagination,
  marginTopPagination,
  handlePageClick,
  onChangeSelected,
  minHeight,
  fieldSelect,
}) => {
  const customClass = cx({
    theme_standard_table_normal: theme_standard_table_normal,
    theme_standard_table_normal_gray: theme_standard_table_normal_gray,
    theme_standard_table_action_pink: theme_standard_table_action_pink,
    theme_standard_table_user: theme_standard_table_user,
    theme_standard_table_group_healthy: theme_standard_table_group_healthy,
    theme_standard_table_normal_brown: theme_standard_table_normal_brown,
  });

  const [_selected, _setSelected] = useState({});
  const [_isSelectedAll, _setIsSelectedAll] = useState(false);

  useEffect(() => {
    onChangeSelected && onChangeSelected(_selected);
  }, [_selected, onChangeSelected]);

  const getDescendantProp = (obj, desc) => {
    var arr = desc.split('.');
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  };

  const handleSelectedAll = (e) => {
    if (_isSelectedAll) {
      _setIsSelectedAll(false);
      _setSelected({});
    } else {
      let selectedTemp = { ..._selected };
      data &&
        data.forEach((element) => {
          selectedTemp[element[fieldSelect]] = true;
        });
      _setIsSelectedAll(true);
      _setSelected(selectedTemp);
    }
  };

  const handleSelectedItem = (e) => {
    let temp = { ..._selected };
    if (temp[e.target.value]) {
      delete temp[e.target.value];
      _setSelected({ ...temp });
      if (_isSelectedAll) {
        _setIsSelectedAll(false);
      }
    } else {
      temp[e.target.value] = true;
      _setSelected({ ...temp });
    }
  };

  return (
    <TableActionStyled
      bgHeader={bgHeader}
      marginTopPagination={marginTopPagination}
      minHeight={minHeight}
    >
      <div className={customClass}>
        <div className="table_container">
          {showTitle && <div className="show_title">{showTitle}</div>}
          <div className="table_header" style={{ background: bgColor }}>
            <div className="header_row">
              {columns &&
                columns.map((e, i) => (
                  <div key={i} className="header_col" style={e.headerStyle}>
                    {i === 0 && (
                      <div className="checkbox_layout">
                        <Inputs.InputCheckBox
                          theme_standard_input_check_box_input_round_2
                          name={i}
                          value={'isSelectedAll'}
                          checked={_isSelectedAll}
                          onChange={handleSelectedAll}
                        />
                      </div>
                    )}
                    {e.renderTitle ? e.renderTitle : e.title}
                  </div>
                ))}
            </div>
          </div>
          <div className="table_body">
            {data && data.length > 0 ? (
              data.map((e, i) => {
                return (
                  <div key={i} className="body_row">
                    {columns.map((e1, i2) => (
                      <div key={i2} className="body_col" style={e1.style}>
                        {i2 === 0 && (
                          <div className="checkbox_layout">
                            <Inputs.InputCheckBox
                              theme_standard_input_check_box_round
                              value={e[fieldSelect]}
                              checked={_selected[e[fieldSelect]] ? true : false}
                              onChange={handleSelectedItem}
                            />
                          </div>
                        )}
                        {useNo && i2 === 1 ? (
                          <div className="no">
                            {e1.render
                              ? e1.render({
                                  origin: e,
                                  value: i + 1,
                                })
                              : pagination.from + i}
                          </div>
                        ) : e1.render ? (
                          e1.render({
                            origin: e,
                            value: getDescendantProp(e, e1.field),
                          })
                        ) : (
                          getDescendantProp(e, e1.field)
                        )}
                      </div>
                    ))}
                  </div>
                );
              })
            ) : (
              <div className="no_data" style={{ textAlign: 'center' }}>
                No data
              </div>
            )}
          </div>
        </div>
        {showPagination && pagination && data && data.length > 0 && (
          <div className="pagi_layout">
            <PaginationCustom
              theme_standard_pagination
              totalPages={pagination?.last_page}
              currentPageData={pagination?.current_page - 1}
              _handlePageClick={handlePageClick}
            />
          </div>
        )}
      </div>
    </TableActionStyled>
  );
};

export default TableAction;
