import apiService from '../apiService';

const apiPath = '/admin-api';

export const userService = {
  // LOGIN USER
  POST_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login`, params);
  },
  GET_LOGOUT: () => {
    return apiService.get(`${apiPath}/logout`);
  },

  GET_PROFILE_ADMIN_ME: () => {
    return apiService.get(`${apiPath}/admins/me`);
  },

  GET_CONTENT_CATEGORY: (stringQuery) => {
    return apiService.get(`${apiPath}/content_categories${stringQuery ? stringQuery : ''}`);
  },

  // CONTENT
  GET_CONTENT_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/contents${stringQuery ? stringQuery : ''}`
    );
  },
  GET_CONTENT_DETAIL: (content_uuid) => {
    return apiService.get(
      `${apiPath}/contents/${content_uuid}`
    );
  },
  POST_CONTENT_DETAIL: (params) => {
    return apiService.post(`${apiPath}/contents`, params);
  },
  PATCH_CONTENT_DETAIL: (content_uuid, params) => {
    return apiService.patch(`${apiPath}/contents/${content_uuid}`, params);
  },
  DELETE_CONTENT_DETAIL: (content_uuid) => {
    return apiService.delete(`${apiPath}/contents/${content_uuid}`);
  },
  POST_UPLOAD_CONTENT_DETAIL: (content_uuid, media_type, params) => {
    return apiService.post_formdata(
      `${apiPath}/contents/${content_uuid}/medias/${media_type}`,
      params
    );
  },
  DELETE_UPLOAD_CONTENT_DETAIL: (content_uuid, media_type) => {
    return apiService.delete(
      `${apiPath}/contents/${content_uuid}/medias/${media_type}`
    );
  },

  // CATEGORY
  GET_CATEGORY_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/content_categories${stringQuery ? stringQuery : ''}`
    );
  },
  POST_CATEGORY_CREATE: (params) => {
    return apiService.post(`${apiPath}/content_categories`, params);
  },
  PATCH_CATEGORY_DETAIL: (category_uuid, params) => {
    return apiService.patch(
      `${apiPath}/content_categories/${category_uuid}`,
      params
    );
  },
  DELETE_CATEGORY_DETAIL: (category_uuid) => {
    return apiService.delete(`${apiPath}/content_categories/${category_uuid}`);
  },
  GET_HOME_CATEGORY_LIST: () => {
    return apiService.get(`${apiPath}/home_content_categories`);
  },
  PUT_HOME_CATEGORY_DETAIL: (params) => {
    return apiService.put(`${apiPath}/home_content_categories/`, params);
  },

  GET_CONTENT_TAGS_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/content_tags${stringQuery ? stringQuery : ''}`
    );
  },
};
