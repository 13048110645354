import { Routes, Route } from 'react-router-dom';
import { ROUTE_PATH } from 'utils/constants/routePath';
import cmsRoutes from './cmsRoutes';
import { Mainlayouts } from 'components';
import LoginContainer from 'containers/LoginContainer';

const Navigations = () => {
  // const liff = window.liff;

  return (
    <Routes>
      <Route path={ROUTE_PATH.LOGIN} element={<LoginContainer />} />
      <Route path="*" element={<LoginContainer />} />
      <Route path={ROUTE_PATH.SYSTEM} element={<Mainlayouts.NavBody />}>
        {cmsRoutes &&
          cmsRoutes.map((e, i) => {
            return (
              <Route
                key={i}
                index={e.index}
                path={e.path}
                element={e.element}
              />
            );
          })}
      </Route>
    </Routes>
  );
};

export default Navigations;
