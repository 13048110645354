import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AvatarNameStyled } from './styled';

const AvatarName = ({
  theme_standard,
  src = '/assets/images/mockups/avatar.png',
  label,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AvatarNameStyled>
      <div className={customClass}>
        <div className="avatar_row">
          <img className="avatar_img" alt="herwill avatar" src={src} />
          <div className="avatar_name">{label}</div>
        </div>
      </div>
    </AvatarNameStyled>
  );
};

AvatarName.propTypes = {};

export default AvatarName;
