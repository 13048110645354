import styled from 'styled-components';

export const ContentContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  .loading_page {
    height: calc(100vh - 189px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .container_main_show {
    padding: 74px 30px;
    min-height: calc(100vh - 85px);
    overflow: auto;
    width: 1220px;
    .loading_page {
      width: 100%;
    }
    .title_show {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .box_title {
        display: flex;
        align-items: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_4};
        .svg_edit {
          display: flex;
          margin-right: 12px;
        }
        &.gap {
          display: flex;
          column-gap: 16px;
        }
        .btn_del {
          width: 144px;
        }
        .btn_add {
          width: 264px;
        }
      }
    }
    .show_table {
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      border-radius: 18px;
      padding: 50px 0px;
      margin-top: 24px;
      display: flex;
      column-gap: 16px;
      .lang_layout {
        .w_280 {
          flex-shrink: 0;
          width: 280px;
          max-height: 691px;
          padding: 2px;
          // -ms-overflow-style: none; /* for Internet Explorer, Edge */
          // scrollbar-width: none; /* for Firefox */
          // overflow-y: scroll;
          // ::-webkit-scrollbar {
          //   display: none; /* for Chrome, Safari, and Opera */
          // }
        }
      }
      .col_2 {
        &.flex_1 {
          flex: 1;
        }
        &.w_280 {
          flex-shrink: 0;
          width: 280px;
        }
        .show_svg {
          display: flex;
          justify-content: center;
          margin-top: 48px;
        }
      }
    }
  }
  .msg_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon_show {
      display: flex;
      margin-bottom: 29px;
    }
    .msg_label {
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      // font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.BLUE_3};
    }
    .btn_show {
      width: 70%;
      margin-top: 61px;
    }
    .sub_label {
      margin-top: 20px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      // font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.BLUE_4};
      cursor: pointer;
    }
  }
`;
