import styled from 'styled-components';

export const CheckboxStyled = styled.div`
  .input_checkbox_container {
    display: flex;
    align-items: center;
    column-gap: 8px;
    input {
      flex-shrink: 0;
      width: 18px;
      height: 18px;
    }
    .placeholder {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.GRAY_1}91;
    }
  }

  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
