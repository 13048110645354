import styled from 'styled-components';

export const AlertStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  z-index: 99;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  .backdrop {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.COLORS.BLACK_3}D6;
  }
  .alert_body {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 16px;
    width: 100%;
    max-width: 450px;
    border: 1px solid ${({ theme }) => theme.COLORS.PINK_1};
    padding: 18px 12px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    .alert_title {
      margin-bottom: 12px;
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S22};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.BLACK_6};
    }
    .alert_description {
      text-align: center;
      padding: 0px 19%;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      color: ${({ theme }) => theme.COLORS.BLACK_7};
    }
    .alert_action_row {
      margin-top: 30px;
      display: flex;
      column-gap: 16px;
      padding: 0px 15%;
      .alert_action_col {
        flex: 1;
      }
    }
  }
`;
