import React, { useEffect } from 'react';
import { CategoryFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';

const CategoryForm = ({ initialValues, onCancel, onSubmit }) => {
  const schema = yup.object().shape({
    name_th: yup.string().required('Category is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({ ...initialValues });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CategoryFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          render={({ field }) => (
            <Inputs.InputTextArea2
              theme_standard_text_area
              {...field}
              maxLength={120}
              height="58px"
              labelTitle="หัวข้อ"
              labelSub="Topic"
              placeholder={'วิดีโอจากผู้เชี่ยวชาญ'}
              errors={errors.name_th?.message}
            />
          )}
          name="name_th"
          defaultValue=""
        />
        <div className="cf_action_row">
          <div className="cf_btn_wrap">
            <Buttons.BgStandard
              theme_cancel
              label="ยกเลิก"
              onClick={onCancel}
            />
          </div>
          <div className="cf_btn_wrap">
            <Buttons.BgStandard type="submit" label="ยืนยัน" />
          </div>
        </div>
      </form>
    </CategoryFormStyled>
  );
};

CategoryForm.propTypes = {};

export default CategoryForm;
