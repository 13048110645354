import React from 'react';
import cx from 'classnames';
import { InputSelectLoadmoreStyled } from './styled';
// import Select, { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

const InputSelectLoadmore = ({
  theme_standard,
  label,
  errors,
  onChange,
  disabled,
  value,
  endPoint,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const styles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      padding: '10px 6.7px',
      boxShadow: '0px 3px 6px 0px rgb(0 0 0 / 16%)',
      borderRadius: '12px',
      color: state.isDisabled && '#3B424C',
      background: state.isDisabled && 'white',
      // '&:hover': {
      //    border: state.isFocused ? 0 : 0
      // }
    }),
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '::-webkit-scrollbar-button': {
        width: '0px',
        height: '0px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#404458',
        border: '0px none #ffffff',
        'border-radius': '50px',
      },
      ' ::-webkit-scrollbar-thumb:hover': {
        background: '#404458',
      },
      '::-webkit-scrollbar-thumb:active': {
        background: '#404458',
      },
      '::-webkit-scrollbar-track': {
        background: '#ffffff',
        border: '0px none #ffffff',
        'border-radius': '42px',
      },
      ' ::-webkit-scrollbar-track:hover': {
        background: ' #ffffff',
      },
      '::-webkit-scrollbar-track:active': {
        background: '#ffffff',
      },
      '::-webkit-scrollbar-corner': {
        background: 'transparent',
      },
    }),
  };

  const loadOptions = async (search, loadedOptions) => {
    const res = await endPoint(
      `?search=${search}&offset=${loadedOptions.length}`
    );
    return {
      options: res.data.map((e) => ({
        label: e.name_th,
        value: e.uuid,
      })),
      hasMore: res.data.length < res.page.per_page ? false : true,
    };
  };

  return (
    <InputSelectLoadmoreStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <AsyncPaginate
          styles={styles}
          isMulti
          value={value}
          loadOptions={loadOptions}
          onChange={onChange}
        />
      </div>
      {errors && <div className="error">{errors}</div>}
    </InputSelectLoadmoreStyled>
  );
};

InputSelectLoadmore.propTypes = {};

export default InputSelectLoadmore;
