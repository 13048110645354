import React, { useState, useEffect } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputBrowseStyled } from './styled';
import { Buttons, Cards } from 'components';
import { useDropzone } from 'react-dropzone';

const InputBrowse = ({
  theme_standard_browse,
  placeholder = 'กรุณาเลือกไฟล์',
  onChange,
  theme_left_right,
  svg,
  acceptType,
  value,
  maxMBFileSizeWarning,
  name,
  errors,
}) => {
  const customClass = cx({
    theme_standard_browse: theme_standard_browse,
  });

  const [files, setFiles] = useState([]);

  const renderAcceptType = (type) => {
    switch (type) {
      // case 'cover':
      //   return 'image/*';
      // case 'audio':
      //   return '.mp3,audio/mpeg3';
      case 'video':
        return {
          'video/mp4': ['.mp4'],
        };
      default:
        return 'video/mp4';
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { ...renderAcceptType(acceptType) },
    onDrop: (acceptedFiles) => {
      if (maxMBFileSizeWarning) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(2)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          alert('ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล');
        }
      }
      let fileTemp = [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isNewUpload: true,
            fieldName: name,
          })
        ),
      ];
      setFiles(fileTemp);
      onChange && onChange(fileTemp);
    },
  });

  useEffect(() => {
    if (value) {
      setFiles(value);
    }
  }, [value]);

  const onClickRemove = (index, data) => {
    if (data.isNewUpload) {
      let filesTemp = files;
      filesTemp.splice(index, 1);
      setFiles([...filesTemp]);
      onChange && onChange(filesTemp);
    } else {
      let filesTemp = files;
      filesTemp[index].isDelete = true;
      filesTemp[index].fieldName = name;
      setFiles([...filesTemp]);
    }
  };

  return (
    <InputBrowseStyled>
      <div className={customClass}>
        <div className="file_location">
          <div className="location">{placeholder}</div>
        </div>
        <div className="btn_browse">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Buttons.BtnNormal label="เลือก" labelBottom="Browse" />
          </div>
        </div>
      </div>
      <aside>
        {files &&
          files.length > 0 &&
          files.map((e, i) =>
            e.isNewUpload ? (
              <div className="body_show_video">
                <Cards.PreviewInput
                  key={i}
                  type={acceptType}
                  src={e.preview}
                  name={e.name}
                  onClickRemove={() => onClickRemove(i, e)}
                  svg={svg}
                  theme_left_right={theme_left_right}
                />
              </div>
            ) : (
              !e.isDelete && (
                <div className="body_show_video">
                  <Cards.PreviewInput
                    key={i}
                    type={acceptType}
                    src={e.media_uri}
                    name={e.name}
                    onClickRemove={() => onClickRemove(i, e)}
                    svg={svg}
                    theme_left_right={theme_left_right}
                  />
                </div>
              )
            )
          )}
      </aside>
      {errors && <div className="errors">{errors}</div>}
    </InputBrowseStyled>
  );
};

InputBrowse.propTypes = {};

// audio/* representing sound files.
// video/* representing video files.
// image/* representing image files.

export default InputBrowse;
