import styled from 'styled-components';

export const InputTextRichStyled = styled.div`
  position: relative;
  .title_layout {
    display: flex;
    justify-content: ${({ flexStyle }) =>
      flexStyle ? flexStyle : 'space-between'};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    align-items: center;
    .show_title {
      display: flex;
      margin-bottom: 8px;
      .title_display {
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        margin-right: 9px;
      }
      .sub_display {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_6};
        padding-top: 4px;
      }
    }
    .num_show {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color: ${({ theme }) => theme.COLORS.GRAY_6};
    }
  }
  .icon {
    position: absolute;
    top: 59px;
    left: 23px;
  }
  .editor_wrap {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.COLORS.PINK_2};
    padding: 1px;
    resize: none;
    overflow: auto;
  }
  .text_area {
    width: 100%;
    box-sizing: border-box;
    resize: none;
    outline: none;
    padding: 20px;
    min-height: ${({ height }) => (height ? height : '140px')};
    font-size: ${(props) =>
      props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : 'kanit_regular'};
    color: ${(props) =>
      props.fontColor ? props.fontColor : ({ theme }) => theme.COLORS.BLUE_4};
    ::placeholder {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      padding-left: ${({ svg }) => (svg ? '40px' : '0px')};
    }
  }
  .error {
    margin-top: 3px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    text-align: left;
  }
  .theme_standard {
  }
`;
