import styled from 'styled-components';

export const InputTagStyled = styled.div`
  .tagInputFieldClass {
    border: 1px solid #ededed;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    width: 100%;
    box-sizing: border-box;
  }
  .suggestionsClass {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    ul {
    }
  }
  .activeSuggestionClass {
    mark {
      background: ${({ theme }) => theme.COLORS.WHITE_1} !important;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }
  }
  .errors {
    margin-top: 4px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
