import styled from 'styled-components';

export const PreviewInputStyled = styled.div`
  width: 100%;
  .preview_container {
    .btn_action {
      margin-top: 12px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .theme_left_right {
    width: 100%;
    display: flex;
    .preview_container {
      display: flex;
      width: 100%;
      .btn_action {
        width: 26%;
        margin-left: 25px;
        margin-top: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
  }
`;
