import React from 'react';
import { ContentContainerStyled } from './styled';
import { Icons, Buttons, Tables, Displays } from 'components';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { connect } from 'react-redux';
import { userService } from 'apiService';
import { toast } from 'react-toastify';
import { withRouter } from 'navigations/withRouter';
import { AlertControl } from 'components/Modals/Alert';
import { setReduxVideoCategory } from 'store/actions';

class ContentContainer extends React.Component {
  state = {
    isLoading: false,
    hasMore: true,
    pagination_catgory: { offset: 0, per_page: 10 },
    dataAllCategory: [],
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    // const { pagination_catgory } = this.state;

    let res = await Promise.all([
      this.fetchContentList(),
    ]);
    if (res) {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchContentList = async (stringQuery = `?per_page=10&offset=0&type=content`) => {
    let res = await userService.GET_CONTENT_LIST(stringQuery);
    
    if (res && res.success) {
      this.setState({
        data: res.data,
        pagination: { ...res.page },
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  // fetchVideoCategory = async (stringQuery) => {
  //   const { dataAllCategory } = this.state;

  //   let res = await userService.GET_CONTENT_CATEGORY(stringQuery);
  //   if (res && res.success) {
  //     let dataTemp = [
  //       {
  //         title: 'ทั้งหมด',
  //         title_en: 'All',
  //         value: 'all',
  //         // score: '',
  //       },
  //     ];

  //     let allData = [...dataAllCategory, ...res.data];
  //     allData.forEach((e) => {
  //       dataTemp.push({
  //         title: e.name_th,
  //         score: e.contents_count,
  //         value: e.uuid,
  //       });
  //     });
  //     this.setState({
  //       categoryList: dataTemp,
  //       dataAllCategory: allData,
  //       hasMore: res.data.length < Number(res.page.per_page) ? false : true,
  //     });
  //   }
  // };

  fetchMoreData = () => {
    const { pagination_catgory } = this.state;
    this.setState({
      pagination_catgory: {
        offset: pagination_catgory.offset + pagination_catgory.per_page + 1,
        per_page: pagination_catgory.per_page,
      },
    });

    this.fetchVideoCategory(
      `?per_page=${pagination_catgory.per_page}&offset=${pagination_catgory.offset + pagination_catgory.per_page + 1
      }`
    );
  };

  // handleClickCategroy = () => {
  //   this.props.router.navigate(`${ROUTE_PATH.VIDEO_CATEGORY}`);
  // };

  handleClickSort = (e) => { };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.CONTENT_DETAIL}/create`);
  };

  handleClickDetail = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.CONTENT_DETAIL_PREVIEW}/${e.uuid}`);
  };

  renderStatus = (e) => {
    switch (e) {
      case false:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {<Icons.StatusPublic />}
            <div style={{ marginLeft: '9px' }}>{` สาธารณะ`}</div>
          </div>
        );

      case true:
        return (
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            {<Icons.StatusPrivate />}
            <div style={{ marginLeft: '9px' }}>{` ไม่เผยแพร่`}</div>
          </div>
        );

      default:
        break;
    }
  };

  handleSelectedFilter = (key, e) => {
    switch (key) {
      case 'categoryLeftMenuFilter':
        const hasAllLeft = e.value === 'all';
        this.setState(
          {
            categoryLeftMenuFilter: hasAllLeft
              ? false
              : `categories=${e.value}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      default:
        break;
    }
  };

  handleJoinQuery = () => {
    const { categoryLeftMenuFilter } = this.state;
    let queryString = [];
    if (categoryLeftMenuFilter) {
      queryString.push(categoryLeftMenuFilter);
    }
    if (queryString && queryString.length > 0) {
      this.fetchContentList(`?${queryString.join('&')}&type=content`);
      this.setState({
        queryFilterAll: `${queryString.join('&')}`,
      });
      this.props.router.navigate(`${ROUTE_PATH.VIDEO}?${queryString}`);
    } else {
      this.fetchContentList();
      this.setState({
        queryFilterAll: false,
      });
      this.props.router.navigate(`${ROUTE_PATH.VIDEO}`);
    }
  };

  handlePageClick = (e) => {
    const { queryFilterAll, languageFilter } = this.state;
    let query = `?type=content&per_page=10&offset=${e.selected * 10}`;
    if (queryFilterAll) {
      query += `&${queryFilterAll}`;
    } else {
      query += `&${languageFilter}`;
    }
    // this.props.history.push(`${ROUTE_PATH.VIDEO}${query}`);
    this.fetchContentList(query);
  };

  handleClickCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  // ========== MUTISELECT DATA TABLE FLOW =================
  // =======================================================
  handleChangeSelectedTable = (e) => {
    this.setState({
      selectedData: Object.keys(e).map((key) => key),
    });
  };
  handleClickDeleteAll = () => {
    AlertControl.show({
      title: 'ยืนยันลบบทความของคุณหรือไม่',
      description: 'คคุณไม่สามารถกู้คืนรายการของคุณได้ หลังจากที่คุณลบไปแล้ว',
      btnLabel1: 'ยืนยัน',
      btnLabel2: 'ยกเลิก',
      onEvent1: () => {
        this.handleDeleteContent();
      },
    });
  };
  handleDeleteContent = async () => {
    const { selectedData } = this.state;
    
    this.setState({
      isLoading: true,
    });
    Promise.all(
      selectedData.map((e) => {
        return new Promise(async (resolve) => {
          let res = await userService.DELETE_CONTENT_DETAIL(e);
          if (res && res.success) {
            resolve(true);
          }
        });
      })
    ).then(async () => {
      toast.success('ลบสำเร็จ');
      this.fetchData();
    });
  };

  render() {
    const {
      isLoading,
      selectedData,
      pagination,
      data,
      // categoryList, data,
      // hasMore,
    } = this.state;
    return (
      <ContentContainerStyled>
        {isLoading ? (
          <div className="loading_page">
            <Displays.Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="container_main_show">
              <div className="title_show">
                <div className="box_title">
                  <div className="svg_edit">
                    <Icons.Content />
                  </div>
                  <div>HerWill Content</div>
                </div>
                <div className="box_title gap">
                  <div className="btn_del">
                    <Buttons.BtnPrefixIcon
                      theme_opacity
                      icon={
                        <Icons.Delete3
                          color={
                            selectedData && selectedData.length > 0
                              ? '#CF1A71'
                              : '#676767'
                          }
                          color2={
                            selectedData && selectedData.length > 0
                              ? '#fafafa'
                              : '#c1beb9'
                          }
                        />
                      }
                      fontSize="18px"
                      fontFamily="kanit_bold"
                      label="Delete"
                      onClick={this.handleClickDeleteAll}
                      disabled={!(selectedData && selectedData.length > 0)}
                    />
                  </div>
                  <div className="btn_add">
                    <Buttons.BtnPrefixIcon
                      theme_red
                      fontSize="16px"
                      fontFamily="kanit_medium"
                      label="อัพโหลดบทความ"
                      onClick={this.handleClickCreate}
                    />
                  </div>
                </div>
              </div>
              <div className="show_table">
                {/* <div className="lang_layout">
                  <div className="w_280">
                    <Lists.Category
                      data={[]}
                      onClickEdit={this.handleClickCategroy}
                      hasMore={hasMore}
                      fetchMoreData={this.fetchMoreData}
                      onClickItem={(e) =>
                        this.handleSelectedFilter('categoryLeftMenuFilter', e)
                      }
                    />
                  </div>
                </div> */}
                <div className="col_2 flex_1">
                  <Tables.TableAction
                    theme_standard_table_action_pink
                    minHeight="674px"
                    columns={columns({
                      handleClickSort: this.handleClickSort,
                      handleClickDetail: this.handleClickDetail,
                      renderStatus: this.renderStatus,
                    })}
                    fieldSelect="uuid"
                    data={data}
                    pagination={data && data.length > 0 && pagination}
                    labelEmpty="ยังไม่มีข้อมูล"
                    showPagination
                    handlePageClick={this.handlePageClick}
                    onChangeSelected={this.handleChangeSelectedTable}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </ContentContainerStyled>
    );
  }
}

const columns = ({ handleClickSort, handleClickDetail, renderStatus }) => [
  {
    title: '',
    field: '',
    headerStyle: { textAlign: 'center', width: '20px', flexShrink: 0 },
    style: { textAlign: 'center', width: '20px', flexShrink: 0 },
  },
  {
    title: 'หัวข้อ',
    field: 'topic',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div
        style={{
          color: '#000000',
          overflow: 'hidden',
          maxWidth: '300px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        onClick={() => handleClickDetail(origin)}
      >
        {value}
      </div>
    ),
  },
  // {
  //   title: (
  //     <div
  //       style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
  //       onClick={() => handleClickSort('d1')}
  //     >
  //       <div>หมวด</div>
  //     </div>
  //   ),
  //   field: 'categories',
  //   headerStyle: {
  //     display: 'flex',
  //     justifyContent: 'center',
  //     width: '100px',
  //     flexShrink: 0,
  //   },
  //   style: {
  //     display: 'flex',
  //     justifyContent: 'center',
  //     width: '100px',
  //     flexShrink: 0,
  //   },
  //   render: ({ origin, value }) => (
  //     <div
  //       style={{
  //         fontSize: '18px',
  //         color: '#000000',
  //         overflow: 'hidden',
  //         whiteSpace: 'nowrap',
  //         textOverflow: 'ellipsis',
  //       }}
  //       onClick={() => handleClickDetail(origin)}
  //     >
  //       {value && value.length > 0 ? value.map((e) => e.name_th).join(',') : ''}
  //     </div>
  //   ),
  // },
  {
    title: (
      <div
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={() => handleClickSort('d3')}
      >
        <div>สถานะ</div>
      </div>
    ),
    field: 'is_draft',
    headerStyle: {
      display: 'flex',
      justifyContent: 'flext-start',
      width: '100px',
      flexShrink: 0,
    },
    style: {
      display: 'flex',
      justifyContent: 'flext-start',
      color: '#707070',
      width: '100px',
      flexShrink: 0,
    },
    render: ({ origin, value }) => (
      <div onClick={() => handleClickDetail(origin)}>{renderStatus(value)}</div>
    ),
  },
  {
    title: 'จำนวนการเข้าดู',
    field: 'total_view_count',
    headerStyle: {
      display: 'flex',
      justifyContent: 'center',
      width: '120px',
      flexShrink: 0,
    },
    style: { textAlign: 'center', width: '120px', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div onClick={() => handleClickDetail(origin)}>{value}</div>
    ),
  },
];

const mapStateToProps = (state) => ({
  videoCategory: state.videoCategory,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxVideoCategory: (data) => dispatch(setReduxVideoCategory(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContentContainer));
