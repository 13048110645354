export default function Delete({ color = '#f0211d', width = '18.719', height = '24' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18.719 24">
      <g id="_38_Delete" data-name="38 Delete" transform="translate(-5.081 -2)">
        <path id="Path_20064" data-name="Path 20064" d="M21.571,8H7.857A.87.87,0,0,0,7,8.9l.771,15.521a2.571,2.571,0,0,0,2.571,2.436h8.743a2.571,2.571,0,0,0,2.571-2.439L22.428,8.9a.87.87,0,0,0-.857-.9Z" transform="translate(-0.274 -0.857)" fill={color} />
        <g id="Group_14931" data-name="Group 14931" transform="translate(5.081 2)">
          <path id="Path_20065" data-name="Path 20065" d="M21.726,5H7.154c-2.768,0-2.762,4.286,0,4.286H21.726C24.494,9.286,24.488,5,21.726,5Z" transform="translate(-5.081 -2.429)" fill={color} />
          <path id="Path_20066" data-name="Path 20066" d="M11,5.429V4.571A2.571,2.571,0,0,1,13.571,2h3.417a2.6,2.6,0,0,1,2.571,2.589l-.012.851a.857.857,0,0,1-1.714-.023l.012-.846a.869.869,0,0,0-.857-.857H13.571a.857.857,0,0,0-.857.857v.857C12.714,5.9,11,5.9,11,5.429Z" transform="translate(-5.926 -2)" fill={color} />
          <path id="Path_20067" data-name="Path 20067" d="M19,23.143V12.857a.857.857,0,1,1,1.714,0V23.143a.857.857,0,0,1-1.714,0Z" transform="translate(-7.069 -3.429)" fill="#fff" />
          <path id="Path_20068" data-name="Path 20068" d="M15,23.143V12.857a.857.857,0,1,1,1.714,0V23.143a.857.857,0,0,1-1.714,0Z" transform="translate(-6.498 -3.429)" fill="#fff" />
          <path id="Path_20069" data-name="Path 20069" d="M11,23.143V12.857a.857.857,0,1,1,1.714,0V23.143a.857.857,0,0,1-1.714,0Z" transform="translate(-5.926 -3.429)" fill="#fff" />
        </g>
      </g>
    </svg>

  );
}
