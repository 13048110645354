import styled from 'styled-components';

export const VideoCategoryContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  .show_loading {
    height: calc(100vh - 189px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content_layout_show {
    width: 1216px;
    .title_show {
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S24};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLUE_4};
      margin-bottom: 50px;
    }
    .group_head_table {
      display: flex;
      justify-content: space-between;
      &.mt {
        margin-top: 55px;
      }
      .box_title {
        display: flex;
        align-items: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_4};
        .svg_edit {
          display: flex;
          margin-right: 13px;
        }
      }
      .group_btn {
        display: flex;
        column-gap: 16px;
      }
    }
    .table_show {
      margin-top: 22px;
    }
  }
`;
