import styled from 'styled-components';

export const InputGroupCheckBox2Styled = styled.div`
  .theme_standard_group_check_box {
    position: relative;
    .icon_filter {
      cursor: pointer;
    }
    .filter_block {
      position: absolute;
      width: ${({ boxSize }) => (boxSize ? boxSize : '210px')};
      z-index: 2;
      top: ${({ topBox }) => (topBox ? topBox : '30px')};
      right: ${({ rightBox }) => (rightBox ? rightBox : '-80px')};
      .btn_show {
        margin-top: 20px;
        padding: 0px 20%;
      }
      .box_display {
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .top_filter {
          background: ${({ theme }) => theme.COLORS.BLUE_14};
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          padding: 10px 19px;
          display: flex;
          font-size: ${({ theme }) => theme.FONT.SIZE.S13};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_12};
          .label_top_filter {
            margin-left: 5px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S13};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.BLUE_13};
          }
        }
        .body_checkbox {
          padding: 10px 19px;
          display: flex;
          flex-wrap: wrap;
          font-size: ${({ theme, fontSize }) =>
            fontSize ? fontSize : theme.FONT.SIZE.S13};
          font-family: ${({ theme, fontFamily }) =>
            fontFamily ? fontFamily : theme.FONT.STYLE.REGULAR};
          color: ${({ theme, fontColor }) =>
            fontColor ? fontColor : theme.COLORS.BLUE_14};
          &:last-child {
            margin-bottom: 0px;
          }
          .container_wrap {
            width: 50%;
            margin-bottom: 5px;
          }

          /* The container */
          .container {
            display: block;
            display: flex;
            position: relative;
            // padding-left: 33px;
            // padding-top: 2px;
            padding-right: 0px;
            margin-bottom: 0px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

          /* Hide the browser's default radio button */
          .container input {
            // position: absolute;
            opacity: 0;
            cursor: pointer;
            left: 0px;
          }

          .lable_show {
            margin-left: 10px;
            color: ${({ theme }) => theme.COLORS.BLACK_4};
          }

          /* Create a custom radio button */
          .checkmark {
            position: absolute;
            margin-top: 1.7px;
            top: 0px;
            left: 0;
            max-height: 18px;
            max-width: 18px;
            width: 100%;
            height: 100%;
            // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            border: 1px solid;

            border-radius: 2px;
          }

          .show_label {
            margin-left: 12px;
          }

          /* On mouse-over, add a grey background color */
          .container:hover input ~ .checkmark {
            // background-color: #ccc;
          }

          /* When the radio button is checked, add a blue background */
          .container input:checked ~ .checkmark {
            // border: 1px solid #F0211E;
            background: #394875;
            &.red {
              background: ${({ theme }) => theme.COLORS.RED_4};
              border-color: ${({ theme }) => theme.COLORS.RED_4};
            }
            &.yellow {
              background: ${({ theme }) => theme.COLORS.YELLOW_2};
              border-color: ${({ theme }) => theme.COLORS.YELLOW_2};
            }
            &.blue {
              background: ${({ theme }) => theme.COLORS.BLUE_15};
              border-color: ${({ theme }) => theme.COLORS.BLUE_15};
            }
            &.blue2 {
              background: ${({ theme }) => theme.COLORS.BLUE_17};
              border-color: ${({ theme }) => theme.COLORS.BLUE_17};
            }
            &.blue3 {
              background: ${({ theme }) => theme.COLORS.BLUE_18};
              border-color: ${({ theme }) => theme.COLORS.BLUE_18};
            }
            &.black {
              background: ${({ theme }) => theme.COLORS.BLUE_13};
              border-color: ${({ theme }) => theme.COLORS.BLUE_13};
            }
            &.gray {
              background: ${({ theme }) => theme.COLORS.BLUE_16};
              border-color: ${({ theme }) => theme.COLORS.BLUE_16};
            }
          }

          /* Create the indicator (the dot/circle - hidden when not checked) */
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          /* Show the indicator (dot/circle) when checked */
          .container input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the indicator (dot/circle) */
          .container .checkmark:after {
            left: 6px;
            top: 2px;
            width: 3px;
            height: 8px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }
  }
`;
