export const ROUTE_PATH = {
  ROOT: '/',
  LOGIN: '/logins',
  SYSTEM: '/system',
  VIDEO: '/system/video',
  VIDEO_DETAIL_PREVIEW: '/system/video/preview-detail',
  VIDEO_DETAIL_PREVIEW_BY_ID: '/system/video/preview-detail/:id',
  VIDEO_DETAIL: '/system/video/detail',
  VIDEO_DETAIL_ADD_EDIT: '/system/video/detail/:id',
  VIDEO_CATEGORY: '/system/video/category',
  CONTENT: '/system/content',
  CONTENT_DETAIL_PREVIEW: '/system/content/preview-detail',
  CONTENT_DETAIL_PREVIEW_BY_ID: '/system/content/preview-detail/:id',
  CONTENT_DETAIL: '/system/content/detail',
  CONTENT_DETAIL_ADD_EDIT: '/system/content/detail/:id',
};
