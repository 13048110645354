import React, { useEffect } from 'react';
import { AddContentFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import { Inputs, Icons, UploadSetImage, UploadSetImage2, Buttons, Displays } from 'components';
// import { userService } from 'apiService';
import { Icons, Buttons, Inputs } from 'components';
import { toast } from 'react-toastify';

const AddContentForm = ({ initialValues, onSubmit, handleBack, isDisabled }) => {
  const schema = yup.object().shape({
    topic: yup.string().nullable().required('Topic is required'),
    body: yup.string().nullable().required('Content is required'),
    link_uri: yup.string().nullable().required('Link is required'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        is_draft: initialValues.is_draft ? statusOptions[0] : statusOptions[1],
        cover: initialValues.cover_uri
          ? [{ media_uri: initialValues.cover_uri }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const statusOptions = [
    {
      label: 'ไม่เผยแพร่',
      value: 'true',
      // icon: <Icons.Lock />,
    },
    {
      label: 'สาธารณะ',
      value: 'false',
      // icon: <Icons.Gobal />,
    },
  ];

  const statusIconOptions = {
    true: <Icons.Lock />,
    false: <Icons.Gobal />,
  };

  const _onSubmit = (values) => {
    let haveDelete = values.cover.some((e) => e.isDelete)
    let haveNew = values.cover.some((e) => e.isNewUpload)

    if (initialValues) {
      if (haveDelete) {
        if (haveNew) {
          onSubmit(values);
        } else {
          toast.error('กรุณาอัพโหลดรูปปก');
        }
      } else {
        onSubmit(values);
      }
    } else {
      if (values.cover.length < 1) {
        toast.error('กรุณาอัพโหลดรูปปก');
      } else {
        onSubmit(values);
      }
    }
  };

  return (
    <AddContentFormStyled>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="clinic_show">
          <div className="back_link">
            <Buttons.BtnLink
              theme_standard_btn_link
              svg={<Icons.ArrowLeft />}
              label="ย้อนกลับ"
              onClick={handleBack}
            />
          </div>
        </div>
        <div className="top_form_show">
          <div className="left_box">
            <div className="svg_edit">
              <Icons.Content />
            </div>
            <div>{initialValues ? 'แก้ไข Content' : 'HerWill Content'}</div>
          </div>
          <div className="btn_add">
            <Buttons.BtnPrefixIcon
              theme_red
              label={initialValues ? 'บันทึกการแก้ไข' : 'บันทึก'}
              disabled={isDisabled}
              type="submit"
              fontSize="16px"
              fontFamily="kanit_medium"
            />
          </div>
        </div>
        <div className="body_form">
          <div className="input_top">
            <div className="layer_two">
              <div className="left_layer_two">
                <div className="zone_upload">
                  <div className="header_zone">
                    <div className="label_1">ภาพปกบทความ</div>
                  </div>
                  <div className="upload_layer">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.UploadSetImage2
                          theme_upload_set_image
                          acceptType="cover"
                          maxMBFileSizeWarning="1"
                          {...field}
                          svg={<Icons.Delete3 />}
                          theme_left_right={false}
                          width="100%"
                          errors={errors.cover?.message}
                        />
                      )}
                      name="cover"
                      defaultValue={[]}
                    />
                  </div>
                </div>
                <div className="mid_form">
                  <div className="line_mid">
                    <div className="mid_left">
                      <div className="group_mid_form">
                        <div className="line_label mt12">
                          <div className="label_1">สถานะ</div>
                          <div className="label_2">Status</div>
                        </div>
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.InputSelectCusIcon
                              theme_standard
                              {...field}
                              iconSvg={<Icons.Lock />}
                              iconSvgClose={<Icons.ArrowDropdown />}
                              iconSvgOpen={
                                <Icons.ArrowDropdown
                                  translate="21.81"
                                  rotate="90"
                                />
                              }
                              options={statusOptions}
                              statusIconOptions={statusIconOptions}
                            />
                          )}
                          name="is_draft"
                          defaultValue={{
                            label: 'ไม่เผยแพร่',
                            value: 'true',
                            // icon: <Icons.Lock />,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right_layer_two">
                <div>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextArea2
                        theme_standard_text_area
                        labelTitle="หัวข้อ"
                        labelSub="Topic"
                        maxLength={27}
                        height="58px"
                        placeholder="หัวข้อ"
                        {...field}
                        // placeholder={t('test')}
                        errors={errors.topic?.message}
                      />
                    )}
                    name="topic"
                    defaultValue=""
                  />
                </div>
                <div className="right_text_rich">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextRich
                        labelTitle="เนื้อหา"
                        labelSub="Content"
                        maxLength={52}
                        placeholder="เนื้อหา"
                        svg={<Icons.Pencil2 />}
                        height="124px"
                        {...field}
                        // placeholder={t('test')}
                        errors={errors.body?.message}
                      />
                    )}
                    name="body"
                    defaultValue=""
                  />
                </div>
                <div className='box_input'>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextArea2
                        theme_standard_text_area
                        labelTitle="ช่องอัพโหลดลิงค์บทความ"
                        maxLength={255}
                        height="58px"
                        placeholder="ช่องอัพโหลดลิงค์บทความ"
                        {...field}
                        // placeholder={t('test')}
                        errors={errors.link_uri?.message}
                      />
                    )}
                    name="link_uri"
                    defaultValue=""
                  />
                  {/* <div className='label_show'>
                    ช่องอัพโหลดลิงค์บทความ
                  </div>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        theme_normal
                        errors={errors.link?.message}
                      />
                    )}
                    name="link"
                    defaultValue=""
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </AddContentFormStyled>
  );
};

AddContentForm.propTypes = {};

export default AddContentForm;
