import { default as Checkbox } from './Checkbox';
import { default as TextField } from './TextField';
import { default as InputGroupCheckBox2 } from './InputGroupCheckBox2';
import { default as InputCheckBox } from './InputCheckBox';
import { default as InputTag } from './InputTag';
import { default as InputTextArea2 } from './InputTextArea2';
import { default as InputTextRich } from './InputTextRich';
import { default as InputSelectCusIcon } from './InputSelectCusIcon';
import { default as InputSelect } from './InputSelect';
import { default as UploadSetImage2 } from './UploadSetImage2';
import { default as InputBrowse } from './InputBrowse';
import { default as InputSelectLoadmore } from './InputSelectLoadmore';

const Export = {
  Checkbox,
  TextField,
  InputGroupCheckBox2,
  InputSelectCusIcon,
  InputCheckBox,
  InputTag,
  InputTextArea2,
  InputTextRich,
  InputSelect,
  UploadSetImage2,
  InputBrowse,
  InputSelectLoadmore
};

export default Export;
