import { combineReducers } from 'redux';
import authenReducer from './authenReducer';
import videoCategoryReducer from './videoCategoryReducer';

const rootReducer = combineReducers({
  authenRedux: authenReducer,
  videoCategory: videoCategoryReducer,
});

export default rootReducer;
