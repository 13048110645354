import styled from 'styled-components';

export const TableSkeleton2Styled = styled.div`
  .top_skeleton {

  }
  .head_skeleton {
    
  }
  .body_skeleton {
    height: 150px;
  }

  .theme_normal_table_skeleton2 {

  }
`;
