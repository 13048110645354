export default function Delete3({ color = '#fff',color2='#f0211d' }) {
  return (
    <svg id="_38_Delete" data-name="38 Delete" xmlns="http://www.w3.org/2000/svg" width="15.599" height="20" viewBox="0 0 15.599 20">
      <path id="Path_20064" data-name="Path 20064" d="M19.143,8H7.714A.725.725,0,0,0,7,8.75l.643,12.934a2.143,2.143,0,0,0,2.143,2.03h7.286a2.143,2.143,0,0,0,2.143-2.032L19.857,8.75A.725.725,0,0,0,19.143,8Z" transform="translate(-5.629 -3.714)" fill={color} />
      <g id="Group_14931" data-name="Group 14931">
        <path id="Path_20065" data-name="Path 20065" d="M18.952,5H6.809a1.787,1.787,0,0,0,0,3.571H18.952A1.787,1.787,0,0,0,18.952,5Z" transform="translate(-5.081 -2.857)" fill={color} />
        <path id="Path_20066" data-name="Path 20066" d="M11,4.857V4.143A2.143,2.143,0,0,1,13.143,2H15.99a2.163,2.163,0,0,1,2.143,2.158l-.01.709a.714.714,0,0,1-1.429-.019l.01-.7a.724.724,0,0,0-.714-.714H13.143a.714.714,0,0,0-.714.714v.714C12.429,5.252,11,5.252,11,4.857Z" transform="translate(-6.772 -2)" fill={color} />
        <path id="Path_20067" data-name="Path 20067" d="M19,21.286V12.714a.714.714,0,0,1,1.429,0v8.571a.714.714,0,1,1-1.429,0Z" transform="translate(-9.058 -4.857)"  fill={color2} />
        <path id="Path_20068" data-name="Path 20068" d="M15,21.286V12.714a.714.714,0,0,1,1.429,0v8.571a.714.714,0,1,1-1.429,0Z" transform="translate(-7.915 -4.857)"  fill={color2} />
        <path id="Path_20069" data-name="Path 20069" d="M11,21.286V12.714a.714.714,0,0,1,1.429,0v8.571a.714.714,0,1,1-1.429,0Z" transform="translate(-6.772 -4.857)"  fill={color2} />
      </g>
    </svg>
  );
}
